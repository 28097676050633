
export default {
  props: {
    msg: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 15,
    },
  },
  computed: {
    is_limit_exceed() {
      return this.msg.length > this.limit
    },
    available_msg() {
      return this.is_limit_exceed
        ? this.$textHelper.limitCharacter(this.msg, this.limit)
        : this.msg
    },
  },
}
