import { Message, Notification } from "element-ui"

const snackbars = ({ mess, level }) => {
  return Message({
    message: mess,
    type: level,
    dangerouslyUseHTMLString: true,
    duration: 4000,
  })
}

const toasts = ({ title, mess, behavior, on_click }) => {
  // const h = $createElement;
  // const on_click = function() {
  //   $nuxt.$router.push({
  //     path: '/smartchat/all',
  //     query: { room: '60227046d19c9d85494e8d3c' }
  //   })
  // }
  // const tem = `<div class="title">{{ title }}</div> <div class="content-wrapper"> <div class="content">{{ mess }}</div></div>`
  // const tem_action = `<div class="title">{{ title }}</div> <div class="content-wrapper"> <div class="content">{{ mess }}</div> <div class="action-wrapper"><div @click="${on_click}">ดูแชท</nuxt-link></div></div>`
  return Notification({
    title: title,
    message: mess,
    dangerouslyUseHTMLString: true,
    duration: behavior == "persistent" ? 0 : 6000,
    onClick: on_click || false,
  })
}

export default { snackbars, toasts }
