
import _ from "lodash"
import ep from "~/api/endpoint"
import { mapState } from "vuex"

export default {
  props: {
    selected_team: {
      type: Array,
      default: () => [],
    },
    is_disabled_default_team: {
      type: Boolean,
      default: false,
    },
    list_id: {
      type: String,
      default: "",
    },
    add_btn_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      input_keyword: "",
      loading_team: false,
      checked_team: [],
      team_list: {
        data: [],
        search_data: [],
      },
    }
  },
  computed: {
    ...mapState({
      oho_member: (state) => state.oho_member.member,
    }),
    my_teams() {
      return _.get(this.oho_member, "teams", [])
    },
    team_is_not_member_update_permission() {
      return this.$permission.validate("team.me-is-not-member.update")
    },
    team_member_remove_permission() {
      return this.$permission.validate("team.member.remove")
    },
  },
  methods: {
    checkDisableAddTeamBtn(team) {
      if (!this.is_disabled_default_team) {
        return false
      } else if (team.type === "default" && this.is_disabled_default_team) {
        return true
      } else if (
        !this.team_is_not_member_update_permission &&
        this.team_member_remove_permission
      ) {
        return !this.my_teams.includes(team._id)
      } else {
        return !this.team_member_remove_permission
      }
    },
    async onVisible() {
      this.visible = true
      this.input_keyword = ""
      this.checked_team = [...this.selected_team]
      await this.getTeamList()
    },
    onChangeCheckbox() {
      const select_team = this.team_list.data.filter((team) =>
        this.checked_team.includes(team._id)
      )
      this.$emit("onAdd", select_team)
    },
    async getTeamList() {
      this.loading_team = true
      try {
        const res = await this.$ohoMemberApi.$get(ep.team, {
          params: {
            is_deactivated: false,
            "$sort[created_at]": 1,
          },
        })
        const data = res
        this.team_list.data = data
        this.team_list.search_data = data
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
      this.loading_team = false
    },
    async getSearchTeamList() {
      this.loading_team = true
      try {
        const res = await this.$ohoMemberApi.$get(ep.team, {
          params: {
            is_deactivated: false,
            "display_name[$search]": this.input_keyword || undefined,
          },
        })
        const data = res
        this.team_list.search_data = data
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
      this.loading_team = false
    },
    searchTeam: _.debounce(function () {
      this.getSearchTeamList()
    }, 300),
  },
}
