
import { mapState } from "vuex"
import ep from "~/api/endpoint"
import _ from "lodash"

export default {
  data() {
    return {
      nav_menu_current: "chat",
      switch_biz_loading: false,
      business_list: [],
      logo_image: require("~/assets/images/logo.png"),
      mascot_image: require("~/assets/images/mascot.png"),
      icon_image: require("~/assets/images/placeholder_business.svg"),
    }
  },
  computed: {
    ...mapState({
      business: (state) => state.business || {},
    }),
  },
  mounted() {
    if (this.$userAgent.is_mobile) this.getBusinessList()
  },
  methods: {
    switchBusiness(id) {
      if (id === _.get(this.business, "_id")) return
      this.nav_menu_current = "chat"
      this.$cookies.set("oho_current_biz", id, {
        path: "/",
      })
      this.switch_biz_loading = true
      window.location = "/"
    },
    async getBusinessList() {
      try {
        const res = await this.$ohoApi.$get(ep.my_member_list, {
          params: {
            $limit: 100,
            user_id: this.$cookies.get("oho_user_id"),
          },
        })
        this.business_list = _.chain(res)
          .get("data")
          .orderBy(["request_status", "business_id.name"], ["desc", "asc"])
          .value()
      } catch (error) {
        // this.$message.error(_.get(error, "response.data"))
      }
    },
  },
}
