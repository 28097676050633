import { render, staticRenderFns } from "./ProfileLabel.vue?vue&type=template&id=4699b337&scoped=true&"
import script from "./ProfileLabel.vue?vue&type=script&lang=js&"
export * from "./ProfileLabel.vue?vue&type=script&lang=js&"
import style0 from "./ProfileLabel.vue?vue&type=style&index=0&id=4699b337&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4699b337",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default,TeamField: require('/builds/oho/oho-web-app/components/Team/Field.vue').default})
