import _ from "lodash"
import Context from "./context.js"
import Message from "./message.js"
import Template from "./template.js"
import TextWriting from "./text-writing.js"

export default function (sf_id, parameter = {}, on_click) {
  const feedback = Context.find((c) => c.id == sf_id)

  if (_.isEmpty(feedback)) return

  let content
  const type_id = _.get(feedback, "type.id")
  const message = _.get(feedback, "message")

  switch (type_id) {
    case "snackbar":
      content = TextWriting(message, parameter)
      Message.snackbars({ mess: content, level: _.get(feedback, "level") })
      break

    case "toasts":
      const behavior = _.get(feedback, "behavior")
      const platform = _.get(parameter, "platform")
      const feedback_title_id = _.get(feedback, "type.tmp_title_id")
      const feedback_content_id = _.get(feedback, "type.tmp_content_id")

      let platform_info = {}
      if (process.client) {
        platform_info = window.$nuxt.$store.getters.getAppPlatform(platform)
      }

      let tmp_content = _.find(Template.message, { id: feedback_content_id })
      const tmp_title = _.chain(Template.title)
        .find({ id: feedback_title_id })
        .get("temp")
        .value()

      if (_.isEmpty(tmp_title) || _.isEmpty(tmp_content)) return

      tmp_content = tmp_content.temp.replace("$message", message)
      content = TextWriting(tmp_content, {
        ...parameter,
        ...feedback,
        user_placeholder_image: require("~/assets/images/Property 1=Empty.png"),
        team_placeholder_image: require("~/assets/images/team_default_image.png"),
      })
      const title = TextWriting(tmp_title, {
        ...parameter,
        ...feedback,
        ...platform_info,
      })

      return Message.toasts({ mess: title + content, behavior, on_click })
      break
  }
}
