import _ from "lodash"

const sort = {
  memberRole: (members) => {
    return (
      _.chain(members)
        .map((member) => {
          switch (member.role) {
            case "owner":
              return {
                ...member,
                priority: 1,
              }
            case "manager":
              return {
                ...member,
                priority: 2,
              }
            case "admin":
              return {
                ...member,
                priority: 3,
              }
            case "sale":
              return {
                ...member,
                priority: 4,
              }
            case "staff":
              return {
                ...member,
                priority: 5,
              }
            case "agent":
              return {
                ...member,
                priority: 6,
              }
            case "oho_support":
              return {
                ...member,
                priority: 7,
              }
          }
        })
        .sortBy(["priority", "display_name"])
        .value() || []
    )
  },
}

export default ({ app }, inject) => {
  inject("sort", sort)
}
