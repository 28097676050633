import _ from "lodash"

export default ({ store }, inject) => {
  const permission = {
    // method สำหรับ check ui ว่ามีสิทธิ์มองเห็นหรือไม่
    validate: (access) => {
      const permissions =
        _.get(store, "state.oho_member.member.role_permission.permissions") ||
        []
      return permissions.includes(access)
    },
    // method สำหรับ check ว่า agent เกี่ยวข้องกับเคสนี้ไหม
    isAgentInvolvedThisCase: (case_id) => {
      let role = _.get(store, "state.oho_member.member.role")
      let is_agent = role === "agent"

      if (!is_agent) return true

      const my_id = _.get(store, "state.oho_member.member._id") || null
      const current_contact = _.get(store, "state.smartchat.current_contact") || {}

      const all_case = _.get(current_contact, "all_case") || []
      const my_involve_case_ids =
        _.get(current_contact, "my_involve_case_ids") || []

      const involved_case_list_from_all_case = all_case
        .filter((d) =>
          d.involved_members.map((i) => i.member_id).includes(my_id)
        )
        .map((d) => d["_id"])
      const involved_case_list_from_my_involve = my_involve_case_ids.map(
        (item) => item["_id"]
      )

      let is_involved_case = [
        ...involved_case_list_from_all_case,
        ...involved_case_list_from_my_involve,
      ].includes(case_id)

      if (is_agent && !is_involved_case) {
        return false
      } else {
        return true
      }
    },
    // method สำหรับ check ว่า agent เกี่ยวข้องกับเคสนี้ไหม สำหรับใช้กับ chat list
    isAgentInvolvedThisCaseForRoomList: (case_id, contact_id) => {
      let role = _.get(store, "state.oho_member.member.role")
      let is_agent = role === "agent"

      if (!is_agent) return true

      const my_id = _.get(store, "state.oho_member.member._id") || null
      const contact_list =
        _.get(store, "state.smartchat.contact_list.data") || []
      const find_contact = contact_list.find((d) => d._id === contact_id)

      const all_case = _.get(find_contact, "all_case") || []
      const my_involve_case_ids =
        _.get(find_contact, "my_involve_case_ids") || []

      let involved_case_list_from_all_case = []
      let involved_case_list_from_my_involve = []

      if (all_case.length > 0) {
        involved_case_list_from_all_case = all_case
          .filter((d) =>
            d.involved_members.map((i) => i.member_id).includes(my_id)
          )
          .map((d) => d["_id"])
      }

      if (my_involve_case_ids.length > 0) {
        involved_case_list_from_my_involve = my_involve_case_ids.map(
          (item) => item["_id"]
        )
      }

      let is_involved_case = [
        ...involved_case_list_from_all_case,
        ...involved_case_list_from_my_involve,
      ].includes(case_id)

      if (is_agent && !is_involved_case) {
        return false
      } else {
        return true
      }
    },
  }

  inject("permission", permission)
}
