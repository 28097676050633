import _ from "lodash"
import moment from "moment"

const is_debug = (process.env.DEBUG || "").indexOf("nuxt") >= 0

// Fix circular reference in JSON.stringify()
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

/**
 * Logger plugin is available as this.$logger helper.
 * It throttle logs and send to Log API in batch.
 *
 * Sample usage:
 *
 * this.$logger.debug(var1, var2, var3, ...)
 * this.$logger.info(var1, var2, var3, ...)
 * this.$logger.warn(var1, var2, var3, ...)
 * this.$logger.error(var1, var2, var3, ...)
 * this.$logger.alert(var1, var2, var3, ...)
 * this.$logger.send({
 *   // required
 *   level: "error",
 *   message: "error occured while click this button",
 *   // optional
 *   metadata: { ... },
 *   status: 400,
 *   error_code: "610-0018",
 * 	 method: "POST", // optional
 *   url: "https://api.oho.chat/auto-reply-pattern/1234",
 *   ua: "<user-agent-string>",
 *   timestamp: 1630031228999
 * });
 *
 * @see https://www.notion.so/bmlpu/Oho-Logging-fc76388f712440e5b770841b7bb6598d#96fe71ad92064222a6e6dd7a147f1f06
 * @class Logger
 */
class Logger {
  logApi
  timeoutRef = null
  buffer = []
  CONSOLE = {
    debug: console.log,
    info: console.info,
    warn: console.warn,
    error: console.error,
    alert: console.error,
  }
  BUFFER_SIZE = 10
  BUFFER_INTERVAL_SECONDS = 5000

  constructor(logApi) {
    this.logApi = logApi
  }

  _humanize(value) {
    // error
    if (value instanceof Error) return value

    // date
    if (value instanceof Date) return moment(value).format()

    // array
    if (Array.isArray(value))
      return JSON.stringify(
        value.map((item) => this._humanize(item)),
        null,
        2
      )

    // object
    if (typeof value === "object")
      return JSON.stringify(value, getCircularReplacer, 2)

    // number
    if (typeof value === "number") return value

    // string
    // else
    return value
  }

  async add(level, messages, options = {}) {
    if (process.env.app_env !== "production") {
      if (typeof this.CONSOLE[level] === "function") {
        this.CONSOLE[level].apply(this, messages)
      }
    }

    const size = this.buffer.length
    // Timestamp is auto added
    if (!("timestamp" in options)) {
      options.timestamp = Date.now()
    }
    // URL is auto added
    if (!("url" in options) && process.client) {
      options.url = window.location.href
    }
    // When debug is ON, print to console as well
    if (is_debug) {
      this.CONSOLE[level](...messages, options)
    }
    // Add log entry to vuffer
    this.buffer.push({ level, messages, options })
    // If this is the first item in buffer, start count down
    if (size === 0) {
      this.timeoutRef = setTimeout(() => {
        this.flush()
      }, this.BUFFER_INTERVAL_SECONDS)
    }
    // If buffer is full, flush now
    if (size + 1 === this.BUFFER_SIZE) {
      this.flush()
    }
  }

  async flush() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
      this.timeoutRef = null
    }
    // Do flush buffer
    this.submit()
  }

  // Submit batch to Log API
  async submit() {
    const buffer = this.buffer
    this.buffer = []
    const body = buffer.map(({ level, messages, options }) => {
      level = level || "debug"
      const messageList = _.castArray(messages).map((value) =>
        this._humanize(value)
      )
      const message = messageList.join(" ")
      return {
        sid: "oho-web-app",
        level,
        message,
        ...options,
      }
    })
    const params = {
      timeout: 5000,
    }
    try {
      await this.logApi.post("/log", body, params)
    } catch (err) {
      console.error("Failed to submit logs", body, err.message)
      return false
    }
    return true
  }

  debug(...args) {
    this.add("debug", args, {})
  }
  info(...args) {
    this.add("info", args, {})
  }
  warn(...args) {
    this.add("warn", args, {})
  }
  error(...args) {
    this.add("error", args, {})
  }
  alert(...args) {
    this.add("alert", args, {})
  }
  send(params) {
    const { level, message, ...options } = params || {}
    this.add(level, [message], options)
  }
}

export default function ({ $axios }, inject) {
  const baseURL = process.env.oho_api_url
  const logApi = $axios.create({
    baseURL,
    withCredentials: true,
  })
  const logger = new Logger(logApi)
  inject("logger", logger)
}
