
export default {
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
    label_value: {
      type: Array,
      default: () => [],
    },
    label_width: {
      type: String,
      default: "115px",
    },
  },

  methods: {
    getDisplayValue(value) {
      return value?.trim() || "-"
    },
  },
}
