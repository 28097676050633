
import moment from "moment"
import ep from "~/api/endpoint"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      q1_rate: null,
      q2_rate: null,
      rate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      suggestion: "",
      loading: false,
      show_dialog: false,
    }
  },
  watch: {
    visible: {
      handler(val) {
        this.show_dialog = val
      },
      immediate: true,
    },
  },
  computed: {
    is_invalid() {
      return !(this.q1_rate && this.q2_rate)
    },
  },
  methods: {
    changeSatisfiedRate() {
      const el = document.getElementsByClassName("satisfied-number")
      for (let i = 0; i < el.length; i++) {
        if (i < this.q1_rate) el[i].classList.add("bold")
        else el[i].classList.remove("bold")
      }
    },
    changeRecommendedRate() {
      const el = document.getElementsByClassName("recommended-number")
      for (let i = 0; i < el.length; i++) {
        if (i < this.q2_rate) el[i].classList.add("bold")
        else el[i].classList.remove("bold")
      }
    },
    async submitSurvey() {
      this.loading = true
      const user_id = this.$cookies.get("oho_user_id")
      const suggestion = this.suggestion === "" ? "-" : this.suggestion
      const submit_data = {
        timestamp: `${moment().format("l")} ${moment().format("LT")}`,
        user_id,
        "คุณมีความพึงพอใจในการใช้งาน Oho Chat": this.q1_rate,
        "คุณมีแนวโน้มจะแนะนำ Oho Chat ให้เพื่อนใช้งาน": this.q2_rate,
        "หากคุณมีข้อเสนอให้ทางเราปรับปรุงหรือพัฒนาส่วนใด ทางเรายินดีรับฟังค่ะ (ไม่บังคับ)":
          suggestion,
      }
      this.q1_rate = null
      this.q2_rate = null
      try {
        await this.$axios.$post(
          `${process.env.survey_url}/002_user_feedback/row`,
          [submit_data]
        )
        await this.$ohoApi.$post(`${ep.user}/${user_id}/responded-survey`, {
          responded_survey: "002",
        })
      } catch (error) {
        this.$logger.error(error)
      }
      this.show_dialog = false
      this.$emit("update:visible", false)
      this.loading = false
    },
    async surveyLater() {
      const user_id = `${this.$cookies.get("oho_user_id")}`
      this.show_dialog = false
      this.$emit("update:visible", false)
      let popup_count = JSON.parse(
        localStorage.getItem("oho_survey_popup_count")
      )
      if (_.isEmpty(popup_count)) {
        popup_count = {}
        popup_count[user_id] = 1
      } else if (!popup_count[user_id]) {
        popup_count[user_id] = 1
      } else {
        popup_count[user_id] += 1
      }
      localStorage.setItem(
        "oho_survey_popup_count",
        JSON.stringify(popup_count)
      )
    },
  },
}
