
import _ from "lodash"
import moment from "moment"

export default {
  data() {
    return {
      current_show: 0,
      previous_data: {},
      next_data: {},
      dialog: false,
      announcement: [],
    }
  },
  mounted() {
    const show_announcement = this.$cookies.get("announcement")
    if (show_announcement && process.env.content_whatsnew_url) {
      this.getAnnouncement()
    }
  },
  methods: {
    async getAnnouncement() {
      try {
        const announcement = await this.$axios.$get(
          `${process.env.content_whatsnew_url}`
        )
        this.validateAnnouncement(announcement)
      } catch (error) {
        this.$logger.error(error)
      }
    },
    validateAnnouncement(announcement) {
      this.announcement = _.filter(announcement, (announce) => {
        // Between start and end date
        if (announce.start_date && announce.end_date) {
          return moment().isBetween(
            announce.start_date,
            moment(announce.end_date).endOf("day")
          )
        }
        // Before end date
        if (!announce.start_date) {
          return moment().isBefore(moment(announce.end_date).endOf("day"))
        }
        // After start date
        return moment().isAfter(announce.start_date)
      })

      if (!_.isEmpty(this.announcement)) {
        this.dialog = true
        this.handleChangeCarousel(0)
      }
    },
    handleChangeCarousel(index_new, index_old) {
      this.current_show = index_new
      this.previous_data = _.get(this.announcement, `[${index_new - 1}]`, {})
      this.next_data = _.get(this.announcement, `[${index_new + 1}]`, {})
    },
    handleClose() {
      // this.$cookies.remove("announcement")
      this.$cookies.set("announcement", false, { path: "/" })
    },
    handleControl(dri) {
      const el = _.get(this.$refs, "carousel")
      if (!el) return
      if (dri == "prev") {
        el.prev()
      } else {
        el.next()
      }
    },
    handleActive(index) {
      const el = _.get(this.$refs, "carousel")
      if (!el) return
      el.setActiveItem(index)
    },
  },
}
