const state = () => ({
  dashboard_donut_chart: [
    "#66C2A5",
    "#FC8D62",
    "#8DA0CB",
    "#E78AC3",
    "#A6D854",
    "#FFD92F",
  ],
})

export default { state }
