import { render, staticRenderFns } from "./TimeCounter.vue?vue&type=template&id=3465ff34&"
import script from "./TimeCounter.vue?vue&type=script&lang=js&"
export * from "./TimeCounter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default})
