import _ from "lodash"
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g
const team_default_logo = require("~/assets/images/team_default_image.png")

const this_obj = {
  $textHelper: {
    limitCharacter: function (text, length) {
      const text_length = _.get(text, "length")
      return text_length && text_length > length
        ? `${text.substring(0, length)}…`
        : text
    },
    createTeamTag: function (name, color, logo) {
      return `
        <div style="background: ${color};" class="team-tag">
          <div class="image-wrap">
            <img src="${logo ? logo : team_default_logo}" />
          </div>
          <div class="name ellipsis">
            ${$nuxt.$textHelper.convertHtmlToString(name)}
          </div>
        </div>
      `
    },
    convertHtmlToString: (text) => {
      return typeof text === "string"
        ? text.replace(/</g, "&lt;").replace(/>/g, "&gt;")
        : text
    },
    displayTextContentWithLimit: (text, limit) => {
      let limit_text = $nuxt.$textHelper.limitCharacter(text, limit)
      let available_text = $nuxt.$textHelper.convertHtmlToString(limit_text)

      return available_text
    },
  },
}

export default function (content, parameter) {
  if (!content) return
  const compiled = _.template(content)
  const preview_message = _.get(parameter, "preview_message")

  if (preview_message) {
    parameter.preview_message =
      $nuxt.$textHelper.convertHtmlToString(preview_message)
  }
  const res = compiled.call(this_obj, parameter)
  return res
}
