
import moment from "moment"

export default {
  props: {
    startDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      interval: null,
      time: null,
    }
  },
  mounted() {
    this.handleTickTime()

    this.interval = setInterval(() => {
      this.handleTickTime()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch: {
    startDate: {
      handler() {
        this.handleTickTime()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    formatTime(diff) {
      const hours = Math.floor(moment.duration(diff).asHours())

      if (hours > 0) {
        const time = this.$formatDate.minuteHourDiffTime(diff)
        return `${hours}:${time}`
      } else {
        return this.$formatDate.fullDiffTime(diff)
      }
    },
    handleTickTime() {
      const diff = moment().diff(moment(this.startDate))
      this.time = this.formatTime(diff)
    },
  },
}
