import _ from "lodash"

export default ({ store }, inject) => {
  const current_member_role = _.get(store, "state.oho_member.member.role")

  const contact = {
    visibility: (contact) => {
      if (current_member_role !== "sale") return true

      // Initialize variables
      // my id
      const my_member_id = _.get(store, "state.oho_member.member._id")
      // my team id
      const my_teams = _.get(store, "state.oho_member.member.teams", [])
      // sale owner data
      const sale_owner = _.get(contact, "sale_owner")

      // id of member that chat is assigned
      const assignee_member_id = _.get(contact, "assignee.member_id._id")
      // team id of member that chat is assigned
      const assinee_team_id = _.get(contact, "assignee.team_id._id")
      // team visibility setting of member that chat is assigned
      const assignee_team_visibility_setting = _.get(
        contact,
        "assignee.team_id.sale_visibility",
        ""
      )

      // id of member that chat is assigning to
      const assign_to_member_id = _.get(contact, "assign_to.member_id._id")
      // team id of member that chat is assigning to
      const assign_to_team_id = _.get(contact, "assign_to.team_id._id")
      // team visibility setting of member that chat is assigning to
      const assign_to_team_visibility_setting = _.get(
        contact,
        "assign_to.team_id.sale_visibility",
        ""
      )

      // id of sale owner
      const sale_owner_member_id = _.get(contact, "sale_owner.member_id._id")
      // team id of member that is sale owner
      const sale_owner_team_id = _.get(contact, "sale_owner.team_id._id")
      // sale owner team visibility setting
      const sale_owner_team_visibility_setting = _.get(
        contact,
        "sale_owner.team_id.sale_visibility",
        ""
      )

      // The current member is chat assignee
      const is_my_chat = assignee_member_id === my_member_id
      // Chat is handled by another member in the team
      const is_my_team_chat = !is_my_chat && my_teams.includes(assinee_team_id)

      // Chat is assigned to the current member
      const is_assign_to_me = assign_to_member_id === my_member_id
      // Chat is assigned to the team of which the current member is a member.
      const is_assign_to_my_team =
        !is_assign_to_me && my_teams.includes(assign_to_team_id)

      // The current member is the sale owner of the chat
      const is_my_contact = sale_owner_member_id === my_member_id
      // Another member in the team is the sale owner of the chat
      const is_my_team_contact =
        !is_my_contact && my_teams.includes(sale_owner_team_id)

      // Chat assignee visibility
      const is_assignee_chat_visibility =
        is_my_chat ||
        (is_my_team_chat && assignee_team_visibility_setting === "team")

      // Chat assign to visibility
      let is_assign_to_chat_visibility = false

      if (is_assign_to_me) {
        is_assign_to_chat_visibility = true
      } else if (is_assign_to_my_team) {
        if (sale_owner) {
          is_assign_to_chat_visibility =
            assign_to_team_visibility_setting === "team"
        } else {
          if (!assign_to_member_id) {
            is_assign_to_chat_visibility = true
          } else {
            is_assign_to_chat_visibility =
              assign_to_team_visibility_setting === "team"
          }
        }
      }

      // Contact visibility
      const is_contact_visibility =
        is_my_contact ||
        (is_my_team_contact && sale_owner_team_visibility_setting === "team")

      return (
        is_assignee_chat_visibility ||
        is_assign_to_chat_visibility ||
        is_contact_visibility
      )
    },
  }

  inject("contact", contact)
}
