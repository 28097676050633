import Vue from "vue"
import _ from "lodash"

const state = () => ({
  members: [],
  teams: [],
})

const mutations = {
  initMembersOnlineStatus(state, data) {
    state.members = data
  },
  initTeamsOnlineStatus(state, data) {
    state.teams = data
  },
  mutateMemberOnlineStatus(state, { index, online_status }) {
    const member = state.members[index]
    Vue.set(state.members, index, {
      ...member,
      online_status: online_status,
    })
  },
  mutateTeamAutoAssignStatus(state, { index, auto_assign_status }) {
    const team = state.teams[index]
    Vue.set(state.teams, index, {
      ...team,
      is_enabled_auto_assign: auto_assign_status,
    })
  },
}

const actions = {
  setOnlineStatusAggregate({ commit }, data) {
    const members = _.get(data, "members") || []
    const teams = _.get(data, "teams") || []

    if (members.length > 0) {
      commit("initMembersOnlineStatus", members)
    }

    if (teams.length > 0) {
      commit("initTeamsOnlineStatus", teams)
    }
  },
  updateMemberOnlineStatus({ commit, state }, data) {
    const find_index = _.findIndex(state.members, { _id: data.member_id })

    if (find_index > -1) {
      commit("mutateMemberOnlineStatus", {
        index: find_index,
        online_status: data.online_status,
      })
    }
  },
  updateTeamAutoAssignStatus({ commit, state }, data) {
    const find_index = _.findIndex(state.teams, { _id: data.team_id })

    if (find_index > -1) {
      commit("mutateTeamAutoAssignStatus", {
        index: find_index,
        auto_assign_status: data.auto_assign_status,
      })
    }
  },
}

export default { state, mutations, actions }
