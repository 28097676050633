
import _ from "lodash"
import moment from "moment"

export default {
  props: {
    show_expand_col: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    getMediaLibraryList: {
      type: Function,
      default: () => {},
    },
    media: {
      type: Object,
      default: () => {},
    },
    current_media: {
      type: Object,
      default: () => {},
    },
    table_height: {
      type: String,
      default: "auto",
    },
    not_accept: {
      type: Array,
      default: () => {
        return []
      },
    },
    show_multiple_select: {
      type: Boolean,
      default: true,
    },
    selected_medias: {
      type: Array,
      default: () => {
        return []
      },
    },
    maximum_selection: {
      type: Number,
      default: 0,
    },
    isMediaSelectable: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      table_data: [],
    }
  },
  computed: {
    total_page_text() {
      const skip = (this.media.page - 1) * this.media.limit
      const total = this.media.total || 0
      const first_count = this.media.total === 0 ? 0 : skip + 1
      const last_count =
        total - skip >= this.media.limit ? skip + this.media.limit : total

      return `${this.$format.numbers(first_count)}-${this.$format.numbers(
        last_count
      )} จาก ${this.$format.numbers(total)}`
    },
  },
  watch: {
    "media.data": {
      handler(newValue) {
        this.table_data = _.map(newValue, (d) => {
          // const actionTotal = (type) =>
          //   _.filter(d.triggers_out, (to) => to.action_type === type).length

          // const suggestion_tags = _.chain(_.get(d, "suggestion_tags", []))
          //   .map((t) => SuggestionTags.find((s) => s.id == t))
          //   .orderBy("priority", "asc")
          //   .take()
          //   .value()
          const diff_created_at = moment().diff(d.created_at, "minutes")

          return {
            ...d,
            new: diff_created_at <= 5 ? true : false,
            created_at_format: this.$formatDate.date(d.created_at),
            updated_at_format: this.$formatDate.date(d.updated_at),
            type_format:
              d.type == "image"
                ? "รูปภาพ"
                : d.type == "video"
                ? "วีดีโอ"
                : d.type == "file"
                ? "ไฟล์"
                : "",
            teams_expand: false,
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    tableRowClassName({ row }) {
      const data_tracking_id = "media-library_list-view_file_detail_click"
      if (this.not_accept.includes(row.type)) {
        return "is-disabled"
      }

      if (
        this.show_multiple_select &&
        this.selected_medias.length === this.maximum_selection
      ) {
        const selected_media_index = _.findIndex(this.selected_medias, {
          _id: row._id,
        })
        return selected_media_index < 0 ? "is-disabled" : data_tracking_id
      }

      if (
        !this.show_multiple_select &&
        row._id === _.get(this.current_media, "_id")
      ) {
        return `is-select ${data_tracking_id}`
      }

      return data_tracking_id
    },
    rowClick(row) {
      const selected_media_index = _.findIndex(this.selected_medias, {
        _id: row._id,
      })
      const is_selected = selected_media_index >= 0
      const is_exceeded_limit =
        this.selected_medias.length === this.maximum_selection

      // Unabled to select unsupported files
      if (this.not_accept.includes(row.type)) return
      // Unabled to select new files after limit is exceeded
      if (this.show_multiple_select && is_exceeded_limit && !is_selected) return

      if (this.show_multiple_select) {
        this.$refs.mediaLibraryTable.toggleRowSelection(row)
      } else {
        this.$emit("selectMedia", row)
      }
    },
    getRowKey(row) {
      return row._id
    },
    changeMediaPage(page) {
      this.$emit("changeMediaPage", page)

      setTimeout(() => {
        this.getMediaLibraryList()
      }, 0)
    },
    setOrderBy() {
      this.media.sort.order_by = this.media.sort.order_by === "-1" ? "1" : "-1"
      this.getMediaLibraryList()
    },
  },
}
