
import _ from "lodash"
const validateEmail = (rule, value, callback) => {
  const is_valid = String(value)
    .trim()
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

  if (value === "") {
    callback(new Error("โปรดระบุอีเมล"))
  } else if (!is_valid) {
    callback(new Error("รูปแบบอีเมลไม่ถูกต้อง"))
  } else {
    callback()
  }
}
const validatePhoneNumber = (rule, value, callback) => {
  const phone_number = String(value)
  const is_valid_phone = phone_number.trim().match(/^0(\d{8,9})$/)
  const is_valid_hotline = phone_number.trim().match(/^(\d{3,4})$/)
  if (value === "") {
    callback(new Error("โปรดระบุเบอร์ติดต่อ"))
  } else if (!is_valid_phone && !is_valid_hotline) {
    callback(new Error("รูปแบบเบอร์ติดต่อไม่ถูกต้อง"))
  } else {
    callback()
  }
}
const validatePlannedChannelOverall = (rule, value, callback) => {
  if (!value) {
    callback(new Error("โปรดเลือกอย่างน้อย 1 บัญชีโซเชียลและระบุจำนวนช่องทาง"))
  } else {
    callback()
  }
}
const validatePlannedChannel = (rule, value, callback) => {
  if (value.choose && value.number == "") {
    callback(new Error("โปรดเลือกจำนวนช่องทาง"))
  } else {
    callback()
  }
}
export default {
  props: {
    closeDialog: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
      add_biz_image: require("~/assets/images/Artwork.svg"),
      is_filled: false,
      form: {
        name: "",
        email: "",
        phone_number: "",
        business_type: "",
        planned_number_of_members: "",
        is_choose_planned_channel: false,
        planned_channel: [
          {
            platform: "facebook",
            label: "Facebook Page",
            form: {
              choose: false,
              number: "",
            },
          },
          {
            platform: "line",
            label: "LINE Official Account",
            form: {
              choose: false,
              number: "",
            },
          },
          {
            platform: "instagram",
            label: "Instagram",
            form: {
              choose: false,
              number: "",
            },
          },
        ],
      },
      rules_page1: {
        name: [
          {
            required: true,
            message: "โปรดระบุชื่อธุรกิจ",
            trigger: "blur",
          },
        ],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        phone_number: [
          { required: true, validator: validatePhoneNumber, trigger: "blur" },
        ],
        business_type: [
          {
            required: true,
            message: "โปรดระบุประเภทธุรกิจ",
            trigger: "blur",
          },
        ],
        planned_number_of_members: [
          {
            required: true,
            message: "โปรดระบุจำนวนสมาชิก",
            trigger: "blur",
          },
        ],
      },
      rules_page2_overall: {
        is_choose_planned_channel: [
          {
            required: true,
            validator: validatePlannedChannelOverall,
            trigger: "blur",
          },
        ],
      },
      rules_page2_each_channel: {
        form: [
          {
            validator: validatePlannedChannel,
            trigger: "blur",
          },
        ],
      },
      business_type_options: [
        "ผู้เชี่ยวชาญ",
        "ร้านอาหารและเครื่องดื่ม",
        "ยานพาหนะ",
        "อสังหาริมทรัพย์",
        "การศึกษา",
        "การท่องเที่ยว",
        "ธุรกิจบันเทิง",
        "สุขภาพ",
        "ความงาม",
        "องค์กร หรือสถาบัน",
        "หน่วยงานราชการ",
        "ค้าขาย, ร้านค้า",
        "ธุรกิจในพื้นที่ประเภทอื่น",
        "องค์กรธุรกิจ, กลุ่มองค์กร",
        "แบรนด์, ผลิตภัณฑ์",
        "หนังสือ, นิตยสาร, หนังสือพิมพ์",
        "ภาพยนตร์",
        "ดนตรี",
        "กีฬา",
        "โทรทัศน์",
        "เว็บไซต์ & บล๊อก",
      ],
      planned_number_of_members_options: [
        "1-2",
        "3-5",
        "6-10",
        "11-20",
        "21-50",
        "51-100",
      ],
      planned_number_of_channels_options: ["1-2", "3-5", "6-10", "10+"],
      create_loading: false,
    }
  },
  watch: {
    form: {
      deep: true,
      handler(newVal) {
        const is_filled = _.chain(newVal)
          .pick([
            "name",
            "email",
            "phone_number",
            "business_type",
            "planned_number_of_members",
          ])
          .some((val) => val != "")
          .value()

        if (is_filled != this.is_filled) {
          this.is_filled = is_filled
          this.$emit("isFilled", this.is_filled)
        }
      },
    },
  },
  methods: {
    isChoosePlannedChannel() {
      this.form.is_choose_planned_channel = false
      for (let value of this.form.planned_channel) {
        this.form.is_choose_planned_channel ||= value.form.choose
        if (!value.form.choose) {
          value.form.number = ""
        }
      }
    },
    numberValidator(value) {
      if (value.length > 0) {
        const last = value.charAt(value.length - 1)
        if (!last.match(/^(\d)$/)) {
          this.form.phone_number = value.slice(0, value.length - 1)
        }
      }
    },
    async goToNext() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        } else {
          this.page = 2
        }
      })
    },
    submitForm() {
      let is_valid = true
      this.$refs["overall"].validate((is_this_valid) => {
        is_valid = is_valid && is_this_valid
      })
      for (let index in this.form.planned_channel) {
        this.$refs.platform[index].validate((is_this_valid) => {
          is_valid = is_valid && is_this_valid
        })
      }
      if (is_valid) {
        this.createBusiness()
      }
    },
    async createBusiness() {
      if (this.create_loading) return
      this.create_loading = true
      const phone_array = [this.form.phone_number.trim()]
      let payload = {
        name: this.form.name.trim(),
        email: this.form.email.trim(),
        phones: phone_array,
        category: this.form.business_type.trim(),
        planned_range_of_members: this.form.planned_number_of_members.trim(),
      }
      // let planned_range_of_channels = []
      // for (let value of this.form.planned_channel) {
      //   if (value.form.choose) {
      //     planned_range_of_channels.push({
      //       platform: value.platform,
      //       range: value.form.number,
      //     })
      //   }
      // }
      // payload.planned_range_of_channels = planned_range_of_channels
      try {
        const res = await this.$ohoApi.$post("/business", payload)
        this.closeDialog()
        const id = _.get(res, "_id")
        this.selectBusiness(id)
      } catch (error) {
        this.$message.error(_.get(error, "response.data"))
      }

      this.create_loading = false
    },
    selectBusiness(id) {
      this.$cookies.set("oho_current_biz", id, {
        path: "/",
      })
      // [TODO : Nick] ปรับ default หน้า smartchat ให้เป็นหน้าแชทของคุณชั่วคราว
      // window.location.replace("/smartchat")
      window.location.replace("/smartchat?status=me")
    },
  },
}
