import _ from "lodash"

const getters = {
  app_platforms: (state, getters, rootState) => {
    return [
      {
        display_name: "Facebook Page",
        name: "facebook",
        icon: rootState.icon.facebook,
      },
      {
        display_name: "LINE Official Account",
        name: "line",
        icon: rootState.icon.line,
      },
      {
        display_name: "Instagram",
        name: "instagram",
        icon: rootState.icon.instagram,
      },
    ]
  },
  contact_platforms: (state, getters, rootState) => {
    return [
      {
        display_name: "Messenger",
        name: "facebook",
        icon: rootState.icon.messenger,
      },
      {
        display_name: "Facebook Page",
        name: "facebook-page",
        icon: rootState.icon.facebook,
      },
      {
        display_name: "LINE OA",
        name: "line",
        icon: rootState.icon.line_app,
      },
      {
        display_name: "Instagram",
        name: "instagram",
        icon: rootState.icon.instagram,
      },
    ]
  },
  getAppPlatform: (state, getters) => (platform) => {
    return _.find(getters.contact_platforms, { name: platform })
  },
  getContactPlatform: (state, getters) => (platform) => {
    const res = _.find(getters.contact_platforms, { name: platform })
    return _.get(res, "display_name")
  },
  getAppPlatformIcon: (state, getters) => (platform) => {
    return _.chain(getters.app_platforms)
      .find({ name: platform })
      .get("icon")
      .value()
  },
  getContactPlatformIcon: (state, getters) => (platform) => {
    return _.chain(getters.contact_platforms)
      .find({ name: platform })
      .get("icon")
      .value()
  },
}

export default { getters }
