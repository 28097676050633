import _ from "lodash"
import moment from "moment"

const state = () => ({
  time_period: [],
  dashboard_tab_active: "Chat Dashboard",
})

const mutations = {
  setTimePeriod(state, data) {
    state.time_period = data
  },
  setDashboardTabActive(state, data) {
    state.dashboard_tab_active = data
  },
}

const getters = {
  time_period_query_api: (state, getters) => {
    const start = _.get(state.time_period, "[0]")
    const end = _.get(state.time_period, "[1]")
    return {
      "date[$gte]": moment(start).format("YYYY-MM-DD"),
      "date[$lte]": moment(end).format("YYYY-MM-DD"),
    }
  },
}

export default { state, mutations, getters }
