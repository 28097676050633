
import _ from "lodash"
import { mapState, mapMutations } from "vuex"
const validateEmail = (rule, value, callback) => {
  const is_valid = String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

  if (value === "") {
    callback(new Error("โปรดระบุอีเมล"))
  } else if (!is_valid) {
    callback(new Error("รูปแบบอีเมลไม่ถูกต้อง"))
  } else {
    callback()
  }
}
export default {
  layout: "no-auth",
  data() {
    const validatePass = (rule, value, callback, form_key) => {
      const is_valid = String(value).match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      )

      if (value === "") {
        callback(new Error("โปรดระบุรหัสผ่าน"))
      }
      // else if (!is_valid) {
      //   callback(
      //     new Error(
      //       "รหัสผ่านต้องประกอบด้วยตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก และตัวเลข อย่างน้อย 8 ตัวอักษร"
      //     )
      //   )
      // }
      else {
        if (this[form_key].confirm_password !== "") {
          this.$refs[form_key].validateField("confirm_password")
        }
        callback()
      }
    }
    const conditionPass = (rule, value, callback) => {
      const password = String(value)
      // If password contains both lower and uppercase characters, increase strength value.
      const pass_lower_uppercase = password.match(
        /([a-z].*[A-Z])|([A-Z].*[a-z])/
      )
      // If it has numbers and characters, increase strength value.
      const pass_numbers =
        password.match(/([a-zA-Z])/) && password.match(/([0-9])/)
      // If it has one special character, increase strength value.
      const pass_special_character = password.match(/([#,?,!,@,,$,%,^,&,*,-])/)
      // If the password is smaller than the specified size
      const pass_length = password.length >= 8

      this.pass_lower_uppercase = pass_lower_uppercase
      this.pass_numbers = pass_numbers
      this.pass_special_character = pass_special_character
      this.pass_length = pass_length

      if (
        pass_lower_uppercase &&
        pass_numbers &&
        pass_special_character &&
        pass_length
      ) {
        callback()
      }
    }
    const validateConfirmPass = (rule, value, callback, form_key) => {
      if (value === "") {
        callback(new Error("โปรดระบุรหัสผ่านอีกครั้ง"))
      } else if (value !== this[form_key].new_password) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"))
      } else {
        callback()
      }
    }
    return {
      facebook_icon: require("~/assets/images/facebook_logo_circle.png"),
      line_icon: require("~/assets/images/icon-line.svg"),
      oho_confirm_email_image: require("~/assets/images/oho_mascot_teamadmin.png"),
      user_setting_loading: false,
      change_password_loading: false,
      show_connect_email_dialog: false,
      show_user_setting_dialog_ref: false,
      show_change_password_dialog: false,
      show_confirm_email_dialog: false,
      confirm_email_loading: false,
      confirm_email_ref_code: null,
      connect_email_loading: false,
      toggle_edit_user: true,
      my_member_array: [],
      display_name: "pawaris pongradsfsdfdsfsdfsdfdsft",
      user_setting_form: {
        name: "",
      },
      connect_email_form: {
        email: "",
        new_password: "",
        confirm_password: "",
      },
      change_password_form: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        name: [
          {
            required: true,
            message: "โปรดระบุชื่อ",
            trigger: "blur",
          },
        ],
        old_password: [
          {
            required: true,
            message: "โปรดระบุรหัสผ่าน",
            trigger: "blur",
          },
        ],
        new_password: [
          {
            required: true,
            validator: (rule, value, callback) =>
              validatePass(rule, value, callback, "change_password_form"),
            trigger: "blur",
          },
          { required: true, validator: conditionPass, trigger: "change" },
        ],
        confirm_password: [
          {
            required: true,
            validator: (rule, value, callback) =>
              validateConfirmPass(
                rule,
                value,
                callback,
                "change_password_form"
              ),
            trigger: "blur",
          },
        ],
      },
      connect_email_rules: {
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        new_password: [
          {
            required: true,
            validator: (rule, value, callback) =>
              validatePass(rule, value, callback, "connect_email_form"),
            trigger: "blur",
          },
          { required: true, validator: conditionPass, trigger: "change" },
        ],
        confirm_password: [
          {
            required: true,
            validator: (rule, value, callback) =>
              validateConfirmPass(rule, value, callback, "connect_email_form"),
            trigger: "blur",
          },
        ],
      },
      old_password_is_visible: false,
      password_is_visible: false,
      new_password_is_visible: false,
      pass_lower_uppercase: false,
      pass_numbers: false,
      pass_special_character: false,
      pass_length: false,
      default_user: { name: "" },
    }
  },
  created() {
    this.my_member_array = [...this.my_member_list]
    this.user_setting_form.name = this.oho_user.name
    this.default_user = { ...this.user_setting_form }
  },
  props: {
    visible: { type: Boolean, default: false },
    my_member: { type: Array },
  },
  computed: {
    ...mapState({
      oho_user: (state) => state.oho_user || {},
      business: (state) => state.business,
      my_member_list: (state) => state.my_member_list || [],
    }),
    created_at() {
      return _.get(this.oho_user, "created_at")
    },
    format_date() {
      return this.$formatDate.date(this.created_at)
    },
    label_value() {
      return [
        { label: "ชื่อผู้ใช้งาน", value: this.user_setting_form.name },
        { label: "วันที่สร้างบัญชี", value: this.format_date },
      ]
    },
    form_item() {
      return [
        { label: "ชื่อผู้ใช้งาน", model: "name" },
        {
          label: "วันที่สร้างบัญชี",
          disabled: true,
          placeholder: this.format_date,
        },
      ]
    },
  },
  watch: {
    visible: {
      handler(val) {
        this.show_user_setting_dialog_ref = val
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setOhoUser"]),
    async updateUser() {
      this.user_setting_loading = true

      try {
        const res = await this.$ohoApi.$patch(`/user/${this.oho_user._id}`, {
          name: this.user_setting_form.name,
        })
        this.$message({
          message: "บันทึกสำเร็จ",
          type: "success",
        })
        this.setOhoUser(res)
        this.default_user.name = res.name
        this.toggle_edit_user = true
      } catch (error) {
        this.$message.error(_.get(error, "response.data"))
      }
      this.user_setting_loading = false
    },

    showChangePasswordDialog() {
      this.show_change_password_dialog = true
      this.show_user_setting_dialog_ref = false
      this.user_setting_loading = false
      this.change_password_loading = false
      this.show_connect_email_dialog = false
      this.change_password_form = {
        old_password: "",
        new_password: "",
        confirm_password: "",
      }
    },
    toggleTypeOldPassword() {
      this.old_password_is_visible = !this.old_password_is_visible
    },
    toggleTypePassword() {
      this.password_is_visible = !this.password_is_visible
    },
    toggleTypeNewPassword() {
      this.new_password_is_visible = !this.new_password_is_visible
    },

    showConnectEmailDialog() {
      this.show_user_setting_dialog_ref = false
      this.show_connect_email_dialog = true
      this.connect_email_form = {
        email: "",
        new_password: "",
        confirm_password: "",
      }
    },
    submitUserSettingForm() {
      this.$refs.user_setting_form.validate((valid) => {
        if (!valid) return
        this.updateUser()
      })
    },
    handleBeforeDialogClose() {
      this.$emit("update:visible", false)
      this.toggleCancelEdit()
    },
    toggleCancelEdit() {
      this.toggle_edit_user = true
      if (this.toggle_edit_user) {
        this.user_setting_form.name = this.default_user.name
      }
    },
    toggleEditUser() {
      this.toggle_edit_user = !this.toggle_edit_user
    },
    submitConnectEmailForm() {
      this.$refs.connect_email_form.validate((valid) => {
        if (!valid) return
        this.connectEmail()
      })
    },
    async resendEmail() {
      this.confirm_email_loading = true

      try {
        await this.$ohoApi.$post(`/user/${this.oho_user._id}/email/connect`, {
          email: this.connect_email_form.email,
          password: this.connect_email_form.new_password,
        })
        this.$message({
          message: "ส่งอีเมลแล้ว",
          type: "success",
        })
      } catch (error) {
        this.$message.error(_.get(error, "response.data"))
      }

      this.confirm_email_loading = false
    },

    async connectEmail() {
      this.connect_email_loading = true
      try {
        const res = await this.$ohoApi.$post(
          `/user/${this.oho_user._id}/email/connect`,
          {
            email: this.connect_email_form.email,
            password: this.connect_email_form.new_password,
          }
        )
        this.setOhoUser(res)
        this.confirm_email_ref_code = _.get(res, "email_verify_reference_code")
        this.show_connect_email_dialog = false
        this.show_confirm_email_dialog = true
      } catch (error) {
        this.$message.error(_.get(error, "response.data"))
      }

      this.connect_email_loading = false
    },

    async changePassword() {
      this.change_password_loading = true

      try {
        await this.$ohoApi.$post(
          `/user/${this.oho_user._id}/email/change-password`,
          {
            old_password: this.change_password_form.old_password,
            new_password: this.change_password_form.new_password,
          }
        )
        this.$message({
          message: "เปลี่ยนรหัสผ่านสำเร็จ",
          type: "success",
        })
        this.show_change_password_dialog = false
      } catch (error) {
        this.$message.error(_.get(error, "response.data"))
      }
      this.change_password_loading = false
    },
    submitChangePasswordForm() {
      this.$refs.change_password_form.validate((valid) => {
        if (!valid) return
        this.changePassword()
      })
    },
  },
}
