import _ from "lodash"

const caseHelper = {
  caseStatus: (case_status, contact_status) => {
    if (case_status === "inprogress" && contact_status === "request") {
      return {
        status: "request",
        text: "เรียกสมาชิก",
        style: "request",
      }
    } else if (case_status === "inprogress") {
      return {
        status: "inprogress",
        text: "กำลังดูแล",
        style: "active",
      }
    } else if (case_status === "done") {
      return {
        status: "done",
        text: "ปิดเคสแล้ว",
        style: "",
      }
    } else if (case_status === "cancel") {
      return {
        status: "cancel",
        text: "ยกเลิก",
        style: "",
      }
    } else if (case_status === "spam") {
      return {
        status: "spam",
        text: "สแปม",
        style: "",
      }
    } else if (case_status === "ignore") {
      return {
        status: "ignore",
        text: "เพิกเฉย",
        style: "",
      }
    } else {
      return {
        status: "",
        text: "",
        style: "",
      }
    }
  },
  // roleLabel: (role) => {
  //   if (role === "owner") {
  //     return "เจ้าของธุรกิจ"
  //   } else if (role === "admin") {
  //     return "แอดมิน"
  //   } else if (role === "staff") {
  //     return "ทีมงาน"
  //   } else {
  //     return null
  //   }
  // }
}

export default ({ app }, inject) => {
  inject("caseHelper", caseHelper)
}
