import _ from "lodash"

const textHelper = {
  limitCharacter: (text, length) => {
    const text_length = _.get(text, "length")
    return text_length && text_length > length
      ? `${text.substring(0, length)}…`
      : text
  },
  convertHtmlToString: (text) => {
    return typeof text === "string"
      ? text.replace(/</g, "&lt;").replace(/>/g, "&gt;")
      : text
  },
}

export default ({ app }, inject) => {
  inject("textHelper", textHelper)
}
