const state = () => ({
  is_saving: false,
  is_saved: false,
  is_fail: false,
  last_updated_by: "",
  last_updated_at: "",
})

const mutations = {
  setIsSaving(state, boolean) {
    state.is_saving = boolean
  },
  setIsSaved(state, boolean) {
    state.is_saved = boolean
  },
  setIsFail(state, boolean) {
    state.is_fail = boolean
  },
  setLastUpdatedBy(state, data) {
    state.last_updated_by = data
  },
  setLastUpdatedAt(state, data) {
    state.last_updated_at = data
  },
}

const actions = {}

const getters = {}

export default { state, mutations, actions, getters }
