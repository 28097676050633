import _ from "lodash"

export default function ({ route, store, redirect, $cookies, $device }) {
  const { public_pages, business, oho_user, oho_member, channels } = store.state
  const logged_in = $cookies.get("oho_user_token") && oho_user
  const route_name = route.path.split("/")[1]
  const biz_route_name = route.path.split("/")[3]
  const current_biz_id = $cookies.get("oho_current_biz")
  const invite_token = $cookies.get("oho_invite_token")
  const base_path = `/business/${
    _.get(route, "params.biz_id") || current_biz_id
  }`
  const app_pages = [
    "auto-reply",
    "contact",
    "dashboard",
    "media-library",
    "onboarding",
    "setting",
    "smartchat",
    "start-oho",
  ]
  const no_cond_pages = [
    "invite",
    "logout",
    "reset-password",
    "verify-account",
    "verify-connect-email",
    "pricing-calculator",
  ]
  const is_public_page = public_pages.includes(route_name)
  const no_cond_page = no_cond_pages.includes(route_name)
  const no_prefix_page = app_pages.includes(route_name)

  if (no_cond_page) return

  if (logged_in) {
    // if (!is_desktop() && route_name !== "smartchat") {
    //   return redirect("/smartchat")
    // }
    // if (is_desktop() && route_name === "go-to-desktop") {
    //   return redirect("/smartchat")
    // }

    // On landing "/add-business"
    // if (route_name === "add-business") return

    // On callback from Facebook perm after "/add-business"
    // e.g. route.fullPath === "/login?action=add-business"
    // if (route_action === "add-business") {
    //   return redirect("/add-business")
    // }

    // if (!has_business) {
    //   if (route_name === "create-business") return
    //   return redirect("/create-business")
    // }

    if (invite_token) return

    if (!oho_member) return redirect("/select-business")

    if (
      business.is_disabled &&
      biz_route_name !== "setting" &&
      !is_public_page
    ) {
      return redirect(`${base_path}/setting/payment`)
    }

    if (
      channels.length === 0 &&
      biz_route_name != "start-oho" &&
      biz_route_name != "setting" &&
      route_name != "select-business" &&
      route_name != "start-oho"
    ) {
      return redirect(`${base_path}/start-oho`)
    }

    if (
      (is_public_page || route_name === "" || biz_route_name == "start-oho") &&
      channels.length !== 0
    ) {
      // [TODO : Nick] ปรับ default หน้า smartchat ให้เป็นหน้าแชทของคุณชั่วคราว
      // return redirect(`${base_path}/smartchat`)
      return redirect(`${base_path}/smartchat?status=me`)
    }

    if (no_prefix_page) {
      return redirect(`${base_path}${route.fullPath}`)
    }
  } else {
    const is_mobile = _.get($device, "isMobile", false)
    const is_crawler = _.get($device, "isCrawler", false)
    const location = _.get(route, "query.location", null)
    const biz_id = _.get(route, "params.biz_id", null)
    const room_id = _.get(route, "query.room", null)

    if (is_public_page) return

    if (is_mobile && location !== "mobile" && !is_crawler) {
      let redirect_mobile_url = "/login?location=mobile"
      if (biz_id) redirect_mobile_url += `&business=${biz_id}`
      if (room_id) redirect_mobile_url += `&room=${room_id}`

      return redirect(redirect_mobile_url)
    } else if (biz_id && room_id) {
      return redirect(`/login?room=${room_id}`)
    } else {
      return redirect("/login")
    }
  }
}
