
export default {
  props: {
    case_data: {
      type: Object,
      default: () => {},
    },
    contact: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    case_status() {
      return this.$caseHelper.caseStatus(
        this.case_data.status,
        this.contact.status
      )
    },
    case_status_template() {
      if (this.case_status.status === "request") {
        return {
          text: this.case_status.text,
          class: "request",
          icon: "account_circle",
          icon_class: "material-icons",
          show_profile: false,
        }
      } else if (
        this.case_status.status === "inprogress" &&
        this.case_data.assignee_member
      ) {
        return {
          text: this.case_status.text,
          class: "inprogress",
          icon: "",
          icon_class: "material-icons-outlined",
          show_profile: true,
        }
      } else if (this.case_status.status === "done") {
        return {
          text: this.case_status.text,
          class: "",
          icon: "check_circle",
          icon_class: "material-icons-outlined",
          show_profile: false,
        }
      } else if (this.case_status.status === "spam") {
        return {
          text: this.case_status.text,
          class: "",
          icon: "error_outline",
          icon_class: "material-icons-outlined",
          show_profile: false,
        }
      } else if (this.case_status.status === "ignore") {
        return {
          text: this.case_status.text,
          class: "",
          icon: "do_disturb_on",
          icon_class: "material-icons-outlined",
          show_profile: false,
        }
      } else {
        return {
          text: "เริ่มเคส",
          class: "",
          icon: "topic",
          icon_class: "material-icons-outlined",
          show_profile: false,
        }
      }
    },
    assignee_member() {
      return _.get(this.case_data, "assignee_member.member_id") || {}
    },
    assignee_team() {
      return _.get(this.case_data, "assignee_member.team_id") || {}
    },
  },
}
