
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    image_url: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#FFE2DD",
    },
    size: {
      type: String,
      default: "",
    },
    is_editing: {
      type: Boolean,
      default: false,
    },
    is_show_remove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_thumbnail: false,
      default_image: require("~/assets/images/team_default_image.png"),
    }
  },
  computed: {
    display_image() {
      // Use thumbnail image of original image first
      const image = this.$thumbnailHelper.replaceImageUrl(
        this.image_url,
        "icon"
      )
      this.is_thumbnail = true
      return image
    },
  },
  methods: {
    replaceByDefault(e) {
      if (this.is_thumbnail) {
        // If cannot fetch thumbnail image, use original image
        e.target.src = this.image_url
        this.is_thumbnail = false
      } else {
        // If cannot fetch original image, show placeholder image
        e.target.src = this.default_image
      }
    },
    onClickRemove() {
      this.$emit("onRemove", this.id)
    },
  },
}
