
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
