
import { mapState } from "vuex"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blockMap: null,
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    drawer_style() {
      const alert_length = _.get(this.alert, "length", 0)
      const is_mobile = _.get(this.$userAgent, "is_mobile", false)
      return alert_length > 0 && !is_mobile ? `top: ${40 * alert_length}px` : ""
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false)
    },
  },
}
