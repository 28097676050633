import { render, staticRenderFns } from "./SwitchBusiness.vue?vue&type=template&id=187a074d&scoped=true&"
import script from "./SwitchBusiness.vue?vue&type=script&lang=js&"
export * from "./SwitchBusiness.vue?vue&type=script&lang=js&"
import style0 from "./SwitchBusiness.vue?vue&type=style&index=0&id=187a074d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187a074d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BusinessImage: require('/builds/oho/oho-web-app/components/BusinessImage.vue').default,TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default,AddBusinessCard: require('/builds/oho/oho-web-app/components/AddBusinessCard.vue').default})
