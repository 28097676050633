
import _ from "lodash"
import { mapMutations, mapState } from "vuex"
import ep from "~/api/endpoint"

export default {
  props: {
    alert: {
      type: Object,
      default: () => {},
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      oho_member_preferences: (state) => state.oho_member_preferences,
    }),
    id() {
      return _.get(this.alert, "id")
    },
    text() {
      return _.get(this.alert, "text", "")
    },
    type() {
      return _.get(this.alert, "type", "")
    },
    action_label() {
      return _.get(this.alert, "action_label", "")
    },
    link() {
      return _.get(this.alert, "link", "")
    },
  },
  methods: {
    ...mapMutations(["removeAlert", "setOhoMemberPreferences"]),
    onClick() {
      if (this.link) {
        this.$router.push(this.link)
      }
    },
    async onClose() {
      const member_preferences_id = _.get(this.oho_member_preferences, "_id")

      if (this.id) {
        this.removeAlert(this.id)
      }

      if (member_preferences_id) {
        await this.handleUpdateIsAckedChannelDeletion(
          member_preferences_id,
          true
        )
      }
    },
    async handleUpdateIsAckedChannelDeletion(member_preferences_id, status) {
      try {
        await this.$ohoMemberApi.$patch(
          `${ep.member_preference}/${member_preferences_id}`,
          {
            is_acked_channel_deletion: status,
          }
        )

        this.setOhoMemberPreferences({
          ...this.oho_member_preferences,
          is_acked_channel_deletion: status,
        })
      } catch (error) {
        this.$logger.error("Error update flag is_acked_channel_deletion", error)
      }
    },
  },
}
