import { render, staticRenderFns } from "./GlobalHeader.vue?vue&type=template&id=87e55c90&scoped=true&"
import script from "./GlobalHeader.vue?vue&type=script&lang=js&"
export * from "./GlobalHeader.vue?vue&type=script&lang=js&"
import style0 from "./GlobalHeader.vue?vue&type=style&index=0&id=87e55c90&prod&lang=scss&"
import style1 from "./GlobalHeader.vue?vue&type=style&index=1&id=87e55c90&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87e55c90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectChannels: require('/builds/oho/oho-web-app/components/SelectChannels.vue').default,ManualLink: require('/builds/oho/oho-web-app/components/ManualLink.vue').default,OnlineStatusDropdown: require('/builds/oho/oho-web-app/components/OnlineStatus/Dropdown.vue').default})
