import moment from "moment"
import _ from "lodash"

const broadcastHelper = {
  generateDefaultTempId: (type) => {
    let result = null
    switch (type) {
      case "message":
        result = {
          available: [...Array(5)].map((_, idx) => `msg-${idx}`),
          used: [],
        }
        break
      case "carousel":
        result = {
          available: [...Array(50)].map((_, idx) => `crs-${idx}`),
          used: [],
        }
        break
      case "action":
        result = {
          available: [...Array(150)].map((_, idx) => `act-${idx}`),
          used: [],
        }
        break
      default:
        result = null
        break
    }
    return result
  },
  isLastestBroadcastBy: (key, data) => {
    const diff_created_at = moment().diff(_.get(data, key), "seconds")
    return diff_created_at <= 5
  },
  transformMessages: (messages, status = "") => {
    return _.map(
      messages,
      ({ order_index, items, display, actions, ...data }, idx) => ({
        ...data,
        order_index: order_index || idx,
        items: _.map(items, ({ actions, ...item }, idx) => ({
          ...item,
          order_index: idx,
          actions: _.map(actions, ({ uri, ...action }, idx) => ({
            ...action,
            uri:
              status === "draft" && uri === "https://www.oho.chat" ? "" : uri,
            order_index: idx,
          })),
        })),
      })
    )
  },
  removeUselessProperties: (data, status) => {
    let message
    const type = _.get(data, "type")
    switch (type) {
      case "text":
        message = _.pick(data, ["type", "text"])
        break

      case "image":
        message = _.pick(data, [
          "type",
          "mediaUrl",
          "media_title",
          "media_size",
          "media_content_type",
        ])
        break

      // type = carousel
      default:
        const carousel_items = _.map(data.items, (item) => {
          const carousel_payload = _.omit(item, [
            "_id",
            "order_index",
            "mediaUrl",
            "img_loading",
            "actions",
            "edit_title",
            "edit_description",
          ])
          const mediaUrl = _.get(item, "mediaUrl")
          const carousel_image_payload = mediaUrl
            ? { mediaUrl: mediaUrl, mediaType: "image" }
            : {}
          const carousel_action_payload = _.map(item.actions, (action) => {
            const action_uri = _.get(action, "uri")
            const action_payload = _.omit(action, ["_id", "order_index", "uri"])
            return {
              ...action_payload,
              uri:
                status === "draft" && _.isEmpty(action_uri)
                  ? "https://www.oho.chat"
                  : action_uri,
            }
          })
          return {
            ...carousel_payload,
            ...carousel_image_payload,
            actions: carousel_action_payload,
          }
        })

        message = {
          type,
          items: carousel_items,
        }
        break
    }
    return message
  },
  displayHighlightText(search, data) {
    return data.map((d) => {
      const name = _.get(d, "display_name", "")
      const highlight_text =
        $nuxt.$smartChatHelper.highlightAllSearchMatches(search, name)

      return highlight_text
        ? {
            ...d,
            highlight_text,
          }
        : d
    })
  },
}
export default ({ app }, inject) => {
  inject("broadcastHelper", broadcastHelper)
}
