// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/auto_icon_white.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".team-logo[data-v-245f1738]{align-items:center;border-radius:25%;display:flex;justify-content:center;padding:2px;position:relative}.team-logo img[data-v-245f1738]{border-radius:25%;height:100%;-o-object-fit:cover;object-fit:cover;width:100%}.team-logo[data-v-245f1738]:after{border-radius:2px;bottom:0;content:\"\";display:flex;outline:1px solid #fff;position:absolute;right:0}.team-logo.xs[data-v-245f1738]{height:16px;width:16px}.team-logo.xs[data-v-245f1738]:after{height:6px;width:6px}.team-logo.sm[data-v-245f1738]{height:20px;width:20px}.team-logo.sm[data-v-245f1738]:after{height:6px;width:6px}.team-logo.md[data-v-245f1738]{height:24px;width:24px}.team-logo.md[data-v-245f1738]:after{height:8px;width:8px}.team-logo.lg[data-v-245f1738]{height:28px;width:28px}.team-logo.lg[data-v-245f1738]:after{height:8px;width:8px}.team-logo.xl[data-v-245f1738]{height:32px;width:32px}.team-logo.xl[data-v-245f1738]:after{height:8px;width:8px}.team-logo.xxl[data-v-245f1738]{height:36px;width:36px}.team-logo.xxl[data-v-245f1738]:after{height:8px;width:8px}.team-logo.is-online[data-v-245f1738]:after{background:#31be00}.team-logo.is-away[data-v-245f1738]:after{background:#ffa41b}.team-logo.is-offline[data-v-245f1738]:after{background:#b0b0b0}.team-logo.is-enabled-auto-assign[data-v-245f1738]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat}.team-logo.is-hide-online-status[data-v-245f1738]:after{content:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
