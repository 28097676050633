import Vue from "vue"

export default function ({ $logger }) {
  const onHandleError = (error) => {
    $logger.error(error)
  }

  Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    onHandleError(err)
  }

  if (process.client) {
    window.onerror = function (message, source, lineno, colno, error) {
      onHandleError(message)
    }
  }
}
