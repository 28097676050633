import { render, staticRenderFns } from "./ChangeRoleAlert.vue?vue&type=template&id=74a0346b&scoped=true&"
import script from "./ChangeRoleAlert.vue?vue&type=script&lang=js&"
export * from "./ChangeRoleAlert.vue?vue&type=script&lang=js&"
import style0 from "./ChangeRoleAlert.vue?vue&type=style&index=0&id=74a0346b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a0346b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default})
