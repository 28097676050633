
import ep from "~/api/endpoint"
import _ from "lodash"
import { mapState } from "vuex"

export default {
  props: {
    group: {
      type: String,
      default: "tag",
    },
    tags: {
      type: Array,
      default: () => [],
    },
    show_first: {
      type: Boolean,
      default: false,
    },
    tag_color: {
      type: String,
      default: "",
    },
    placeholder_input: {
      type: String,
      default: "ค้นหาหรือสร้างแท็กใหม่",
    },
    select_btn_text: {
      type: String,
      default: "เพิ่มแท็ก",
    },
    add_tag_data_tracking_id: {
      type: String,
      default: "",
    },
    select_tag_data_tracking_id: {
      type: String,
      default: "",
    },
    create_tag_data_tracking_id: {
      type: String,
      default: "",
    },
    add_btn: {
      type: String,
      default: "text",
    },
    edit_tag: {
      type: Boolean,
      default: true,
    },
    is_show_open_popup_button: {
      type: Boolean,
      default: false,
    },
    is_show_tag_button: {
      type: Boolean,
      default: false,
    },
    is_show_untag_button: {
      type: Boolean,
      default: false,
    },
    is_show_create_button: {
      type: Boolean,
      default: false,
    },
    is_show_update_button: {
      type: Boolean,
      default: false,
    },
    is_show_remove_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sugges_loading: false,
      loadmore_loading: false,
      show_popover: false,
      sugges_keyword: {
        data: [],
        total: 0,
      },
      input_keyword: "",
      sugges_keyword_skip: 0,
      is_editing_tag: false,
      current_editing: {
        _id: "",
        keyword: "",
      },
      remove_dialog: false,
    }
  },
  computed: {
    ...mapState({
      oho_member: (state) => state.oho_member.member || {},
    }),
    sugges_list() {
      let data = []
      if (this.input_keyword) {
        const keyword_data = this.sugges_keyword.data || []
        const new_data = keyword_data.map((d) => {
          const regex = new RegExp(this.escapeRegExp(this.input_keyword), "i")
          const highlight_word = this.$smartChatHelper.highlightSearchMatch(
            this.input_keyword,
            d.keyword,
            false,
            regex
          )

          return highlight_word
            ? {
                ...d,
                highlight_word,
              }
            : d
        })
        data = new_data
      } else {
        data = this.sugges_keyword.data || []
      }

      data = data.map((d) => {
        const checked = _.find(this.tags, ["_id", d._id]) ? true : false
        return { ...d, checked }
      })
      return data
    },
    suggest_new_tag() {
      const data = this.sugges_keyword.data
      if (!data) return ""
      const found = data.find((d) => d.keyword === this.input_keyword)
      if (found) return ""
      return this.input_keyword
    },
    find_duplicate_tag() {
      const current_editing = this.current_editing.keyword
      const found = this.tags.find((d) => d === current_editing.keyword)
      if (found && found._id !== current_editing._id) {
        return true
      } else {
        return false
      }
    },
    no_more() {
      return this.sugges_list.length >= this.sugges_keyword.total
    },
    disabled_load_more() {
      return this.loadmore_loading || this.sugges_loading || this.no_more
    },
  },
  mounted() {
    // this.showTagPopover();
    if (this.show_first) {
      this.show_popover = true
      this.showTagPopover()
    }
  },
  methods: {
    has_highlight_word(item) {
      let highlight_word = _.get(item, "highlight_word")
      return !_.isEmpty(highlight_word)
    },
    setDefaultSkip() {
      this.sugges_keyword_skip = 0
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
    },
    async createTag(keyword) {
      this.sugges_loading = true
      try {
        const data = {
          group: this.group,
          keyword: keyword,
        }
        const res = await this.$ohoMemberApi.$post(ep.keyword, data)
        this.$emit("createTag", res)
        this.addTagForm(res)
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
      this.sugges_loading = false
    },
    handleClosePopover() {
      this.$emit("close", true)
    },
    addTagForm(value) {
      if (!this.is_show_tag_button) return
      this.$emit("addTag", value)
      this.show_popover = false
    },
    async getKeyword() {
      try {
        const res = await this.$ohoMemberApi.$get(ep.keyword, {
          params: {
            group: this.group,
            "$sort[count]": !this.input_keyword ? "-1" : undefined,
            "keyword[$search]": this.input_keyword || undefined,
            $limit: 40,
            $skip: this.sugges_keyword_skip,
          },
        })
        return { ...this.sugges_keyword, ...res }
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
        return this.sugges_keyword
      }
    },
    resetEditTagForm() {
      this.is_editing_tag = false
      this.current_editing = {
        _id: "",
        keyword: "",
      }
    },
    async showTagPopover() {
      this.sugges_keyword = {}
      this.resetEditTagForm()
      this.input_keyword = ""
      this.setDefaultSkip()
      await this.$nextTick()
      this.$refs.search_input.focus()
      this.sugges_loading = true
      this.sugges_keyword = await this.getKeyword()
      this.sugges_loading = false
    },
    loadMoreKeyword: _.debounce(async function () {
      this.loadmore_loading = true
      this.sugges_keyword_skip += 40
      const res = await this.getKeyword()
      const data = _.get(res, "data")
      this.sugges_keyword.data.push(...data)
      this.loadmore_loading = false
    }, 300),
    searchKeyword: _.debounce(async function () {
      this.sugges_loading = true
      this.resetEditTagForm()
      this.setDefaultSkip()
      const res = await this.getKeyword()
      const data = _.get(res, "data")
      const total = _.get(res, "total")
      this.sugges_keyword.data = data
      this.sugges_keyword.total = total
      this.sugges_loading = false
    }, 300),
    onSelectEditTag(tag) {
      this.input_keyword = ""
      this.is_editing_tag = true
      this.current_editing._id = tag._id
      this.current_editing.keyword = tag.keyword
    },
    async handleSubmitEditTag() {
      try {
        const data = {
          _id: this.current_editing._id,
          group: this.group,
          keyword: this.current_editing.keyword,
        }
        const res = await this.$ohoMemberApi.$post(ep.keyword, data)
        const tag_index = this.sugges_keyword.data.findIndex(
          (tag) => tag._id === res._id
        )
        if (tag_index > -1) {
          this.$set(this.sugges_keyword.data, tag_index, res)
        }
        this.$emit("editingTag", res)
        await this.$nextTick()
        this.resetEditTagForm()
        this.input_keyword = ""
      } catch (error) {
        if (_.get(error, "response.status") === 400) {
          this.$message.error("แก้ไขไม่สำเร็จ เนื่องจากแท็กซ้ำ")
        }
        this.$logger.error(_.get(error, "response.data"))
      }
    },
    async handleSubmitRemoveTag() {
      this.remove_dialog = false
      try {
        const tag_id = _.get(this.current_editing, "_id")
        await this.$ohoMemberApi.$delete(`${ep.keyword}/${tag_id}`)
        const tag_index = this.sugges_keyword.data.findIndex(
          (item) => item._id === tag_id
        )
        if (tag_index > -1) {
          this.sugges_keyword.data.splice(tag_index, 1)
        }
        this.resetEditTagForm()
        this.input_keyword = ""
        this.$emit("deleteTag", tag_id)
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    },
  },
}
