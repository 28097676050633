
export default {
  props: {
    image_url: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
    },
    shape: {
      type: String,
      default: "square",
    },
  },
  data() {
    return {
      is_thumbnail: false,
      default_image: require("~/assets/images/placeholder_business.svg"),
    }
  },
  computed: {
    display_image() {
      // Use thumbnail image of original image first
      const image = this.$thumbnailHelper.replaceImageUrl(this.image_url)
      this.is_thumbnail = true
      return image
    },
  },
  methods: {
    replaceByDefault(e) {
      if (this.is_thumbnail) {
        // If cannot fetch thumbnail image, use original image
        e.target.src = this.image_url
        this.is_thumbnail = false
      } else {
        // If cannot fetch original image, show placeholder image
        e.target.src = this.default_image
      }
    },
  },
}
