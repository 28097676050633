
  import moment from "moment"

  export default {
    props: {
      date: {
        type: Number,
        default: "",
      },
    },
    computed: {
      formatTime() {
        const date_formated = moment(this.date)
        const hours = Math.floor(moment.duration(date_formated).asHours())
        if (hours > 0) {
          const time = this.$formatDate.minuteHourDiffTime(date_formated)
          return `${hours}:${time}`
        } else {
          return this.$formatDate.fullDiffTime(date_formated)
        }
      },
    },
  }
  