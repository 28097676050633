import { Message } from "element-ui"

let snackbar = null

const closeSnackbarMessage = () => {
  snackbar.close()
  snackbar = null
}

const snackbarMessage = (current_count = 0, all_count = "", close) => {
  current_count = current_count
  all_count = all_count

  const message_option = {
    customClass: "sendind-bulk-message",
    dangerouslyUseHTMLString: true,
    message: `<div class="content-wrapper">
      <div class="content">
      <i class="donut-spinner"></i>
      <span id="bulk-message-count" class="count">${current_count}</span> / <span id="bulk-message-all-count" class="all-count">${all_count}</span> รายชื่อ ถูกส่งข้อความ </div>
        <div id="bulk-message-close" class="btn-close">ยกเลิก</div>
      </div>`,
    duration: 180000,
  }

  snackbar = Message(message_option)

  const el_close = document.getElementById("bulk-message-close")
  if (close) {
    el_close.style.display = "block"
    el_close.addEventListener("click", () => {
      closeSnackbarMessage()
      close.event()
    })

    setTimeout(() => {
      el_close.style.display = "none"
    }, close.time)
  } else {
    el_close.style.display = "none"
  }
}

const updateStatus = (current_count = 0, all_count = "", close) => {
  if (!snackbar) {
    snackbarMessage(current_count, all_count, close)
  }
  const el_count = document.getElementById("bulk-message-count")
  const el_all_count = document.getElementById("bulk-message-all-count")

  el_count.innerHTML = current_count
  el_all_count.innerHTML = all_count
}

export default ({ app }, inject) => {
  inject("bulkMessageStatus", updateStatus)
  inject("closeBulkMessageStatus", closeSnackbarMessage)
}
