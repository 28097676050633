
import _ from "lodash"
import ep from "~/api/endpoint"
import { mapState, mapMutations, mapGetters } from "vuex"

export default {
  data() {
    return {
      onboarding_show: false,
      drawer: false,
      has_request: false,
      show_user_setting: false,
      profile_dialog_visible: false,
      user_settings_dialog_visible: false,
      current_menu: `/${this.$route.path.split("/")[3]}`,
      member_me: {},
      member_default: {},
      // current_business: {
      //   _id: "",
      //   name: "",
      //   image_url: "",
      // },
      // current_menu: `/${this.$route.path.split("/")[1]}`,
      // business: {
      //   _id: "",
      //   name: "",
      //   image_url: "",
      // },
    }
  },
  computed: {
    ...mapState({
      menu_list: (state) => state.menu.menu_list,
      oho_member: (state) => state.oho_member,
      business: (state) => state.business || {},
      my_member_list: (state) => state.my_member_list || [],
    }),
    ...mapGetters(["nav_menu", "base_path"]),
    member() {
      return _.get(this.oho_member, "member") || {}
    },
    my_role() {
      return _.get(this.oho_member, "member.role") || null
    },
    menu_list_top() {
      let menu = _.filter(this.nav_menu, (m) => !m.is_bottom)

      // [TODO : Nick] ซ่อนเมนู dashboard ชั่วคราวของ role staff, agent, sale
      // เอากลับมาอีกครั้งหลังจากทำเรื่อง query dashboard admin perf
      if (!this.menu_dashboard_view_permission) {
        menu = _.filter(menu, (m) => m.path_id !== "dashboard")
      }

      // เปลี่ยน path menu ให้ตรงตามตั้งค่าหน้าแรกของแชท
      if (this.my_role !== "agent") {
        const chat_landing_page = _.get(
          this.oho_member,
          "member.chat_landing_page.web_app"
        )
        const chat_landing_page_path =
          chat_landing_page === "all_chat"
            ? "/smartchat"
            : "/smartchat?status=me"
        const smartchat_path = `${this.base_path}${chat_landing_page_path}`
        const smartchat_menu_index = _.findIndex(menu, { path_id: "smartchat" })
        menu[smartchat_menu_index].path = smartchat_path
      }

      return menu
    },
    menu_list_bottom() {
      return _.filter(
        this.nav_menu,
        (m) =>
          m.is_bottom && m.path_id != "setting" && m.path_id != "onboarding"
      )
    },
    menu_setting() {
      return _.filter(
        this.nav_menu,
        (m) =>
          m.is_bottom && (m.path_id == "setting" || m.path_id == "onboarding")
      )
    },
    menu_dashboard_view_permission() {
      return this.$permission.validate("menu-dashboard.view")
    },
  },
  watch: {
    "$route.path"() {
      this.current_menu = `/${this.$route.path.split("/")[3]}`
    },
    current_menu: {
      handler() {
        if (this.drawer) this.drawer = false
      },
    },
    my_member_list: {
      handler(val) {
        if (val.length > 1) {
          const my_member_list_expected_current = val.filter(
            (member) => member.business_id._id !== this.business._id
          )
          const other_biz_has_request = _.find(
            my_member_list_expected_current,
            "request_status"
          )
          this.has_request = other_biz_has_request ? true : false
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setMyProfileDialogVisible", "setOwners"]),
    errorHandler() {
      return this.business.image_url ? false : true
    },
    // async getBusiness() {
    //   try {
    //     this.business_id = this.$cookies.get("oho_current_biz")
    //     this.business_id = _.get(this.$route, "params.biz_id")
    //     const business = await this.$ohoMemberApi.$get(
    //       `${ep.business}/${this.business_id}`
    //     )
    //     this.current_business = { ...this.current_business, ...business }
    //     this.current_business = _.pick(this.current_business, [
    //       "_id",
    //       "image_url",
    //       "name",
    //     ])
    //   } catch (error) {
    //     this.$message.error(_.get(error, "response.data"))
    //   }
    // },
    async getMyProfile() {
      try {
        const member = await this.$ohoMemberApi.$get(`${ep.member_me}`, {
          params: {
            "$populate[0][path]": "teams",
            "$populate[0][select]": [
              "display_name",
              "logo_url",
              "color_hex_code",
              "type",
              "created_at",
              "is_deactivated"
            ],
            "$populate[1][path]": "user_id",
            "$populate[1][select]": ["email"],
          },
        })

        this.member_me = {
          ...this.member_me,
          ...member,
        }
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    },
    async editMyProfile() {
      const owners = await this.getTotalOwner()
      this.setOwners(owners)
      this.setMyProfileDialogVisible(true)
    },
    async getTotalOwner() {
      try {
        // this.business_id = this.$cookies.get("oho_current_biz")
        this.business_id = _.get(this.$route, "params.biz_id")
        const response = await this.$ohoMemberApi.$get(ep.member, {
          params: {
            role: "owner",
            is_deactivated: false,
          },
        })
        return _.get(response, "total") || 0
      } catch (error) {
        this.$logger.error(_.get(error, "response.statusText"))
      }
    },
    async handleClickEditProfile() {
      const owners = await this.getTotalOwner()
      await this.getMyProfile()
      this.profile_dialog_visible = true
      this.member_default = _.cloneDeep(this.member_me)
      this.setOwners(owners)
    },
    async handleClickUserSetting() {
      this.user_settings_dialog_visible = true
    },
    async handleClickAccountSettings() {
      this.show_user_setting = true
    },
    async CountOtherBusinessRequest() {
      try {
        const res = await this.$ohoApi.$get(ep.my_member_list, {
          params: {
            $limit: 50,
            user_id: this.$cookies.get("oho_user_id"),
          },
        })
        const my_member_list = _.get(res, "data")
        if (my_member_list.length > 1) {
          const my_member_list_expected_current = my_member_list.filter(
            (member) => member.business_id._id !== this.business._id
          )
          const other_biz_has_request = _.find(
            my_member_list_expected_current,
            "request_status"
          )
          this.has_request = other_biz_has_request ? true : false
        }
      } catch (error) {
        // this.$message.error(_.get(error, "response.data"))
      }
    },
    isRouteActive(path_id) {
      if (this.$route.path.split("/")[3] === path_id) {
        return true
      } else {
        return false
      }
    },
  },
}
