import _ from "lodash"

const SUPPORT_EXTENSIONS = [
  // Support common image and video formats
  "jpeg",
  "jpg",
  "png",
  "gif",
  "webp",
  "mp4",
  "mov",
  "webm",
  // Not support formats
  // "avi", // Mimetype: "video/x-msvideo", "video/avi" yet
]

const thumbnailHelper = ({ $logger }) => ({
  /**
   *
   * @param {String} originalUrl Image URL that uploaded from File API
   * @param {String} sizeGroup Either "thumbnail" (500px), "profile" (200px), "icon" (100px). Default to "profile".
   * @returns {String} Thumbnail URL of the specific size
   */
  replaceImageUrl: (originalUrl, sizeGroup = "profile") => {
    if (!originalUrl) return originalUrl

    let url = originalUrl

    try {
      // File extension e.g. "jpeg" or "mp4"
      const ext = originalUrl.split(".").pop()

      // If not support, reuturn originalUrl
      if (!SUPPORT_EXTENSIONS.includes(ext)) return originalUrl

      // Support thumbnail for image and video
      // This regex will match last occurrence of extension e.g. ".jpeg"
      // and replace it with "_thumbnail.webp"
      const regexExtension = new RegExp(`\\.${ext}$`)
      const fileSuffix = `_${sizeGroup}.webp`
      url = originalUrl.replace(regexExtension, fileSuffix)
    } catch (err) {
      $logger.debug("Failed to prepare thumbnail URL", err)
    }

    return url
  },
})

export default ({ $logger }, inject) => {
  inject("thumbnailHelper", thumbnailHelper({ $logger }))
}
