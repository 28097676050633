
import _ from "lodash"

export default {
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
    tag_size: {
      // "", "large"
      type: String,
      default: "",
    },
    maximum_display_tag: {
      type: Number,
      default: 2,
    },
    show_all_tag: {
      type: Boolean,
      default: false,
    },
    is_editing: {
      type: Boolean,
      default: false,
    },
    is_show_remove: {
      type: Boolean,
      default: false,
    },
    onAdd: {
      type: Function,
      default: () => {},
    },
    onRemove: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    team_tag_data() {
      const tag_list = _.cloneDeep(this.teams) || []
      let more_tag = []

      if (tag_list.length > 0 && !this.show_all_tag) {
        more_tag = tag_list.splice(this.maximum_display_tag)
      }

      return {
        tag_list,
        more_tag,
      }
    },
  },
  methods: {
    getOtherTeamTagNameList(list) {
      return list.map((item) => item.display_name).join(", ")
    },
  },
}
