
import { mapState } from "vuex"
import _ from "lodash"

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    logo_url: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#FFD4D4",
    },
    disabled_tooltip: {
      type: Boolean,
      default: false,
    },
    is_hide_online_status: {
      type: Boolean,
      defualt: false,
    },
    size: {
      type: String,
      default: "md", // xs, sm, md, lg, xl
    },
  },
  data() {
    return {
      is_thumbnail: false,
      default_image: require("~/assets/images/team_default_image.png"),
      auto_assign_enabled: false,
      online_status: "offline",
    }
  },
  watch: {
    teams: {
      immediate: true,
      deep: true,
      handler() {
        this.findTeamOnlineStatus()
      },
    },
    current_contact: {
      handler() {
        this.findTeamOnlineStatus()
      },
    },
  },
  computed: {
    ...mapState({
      teams: (state) => state.online_status.teams || [],
      current_contact: (state) => state.smartchat.current_contact,
    }),
    display_image() {
      // Use thumbnail image of original image first
      const image = this.$thumbnailHelper.replaceImageUrl(this.logo_url, "icon")
      this.is_thumbnail = true
      return image
    },
    online_status_class() {
      switch (this.online_status) {
        case "online":
          return "is-online"
        case "away":
          return "is-away"
        case "offline":
          return "is-offline"
        default:
          return "is-offline"
      }
    },
  },
  methods: {
    replaceByDefault(e) {
      if (this.is_thumbnail) {
        // If cannot fetch thumbnail image, use original image
        e.target.src = this.logo_url
        this.is_thumbnail = false
      } else {
        // If cannot fetch original image, show placeholder image
        e.target.src = this.default_image
      }
    },
    findTeamOnlineStatus() {
      const team = _.find(this.teams, {
        _id: this.id,
      })
      const online_status = _.get(team, "online_status") || "offline"
      const is_enabled_auto_assign =
        _.get(team, "is_enabled_auto_assign") || false
      this.online_status = online_status
      this.auto_assign_enabled = is_enabled_auto_assign
    },
  },
}
