
import _ from "lodash"
import ep from "~/api/endpoint"
import { mapState, mapActions } from "vuex"

export default {
  data() {
    return {
      current_status: "online", // "online", "away", "offline"
      status: [
        {
          label: "ออนไลน์",
          value: "online",
        },
        {
          label: "ไม่อยู่",
          value: "away",
        },
        {
          label: "ไม่ระบุ",
          value: "offline",
        },
      ],
    }
  },
  watch: {
    members: {
      immediate: true,
      deep: true,
      handler() {
        this.findMemberOnlineStatus()
      },
    },
  },
  computed: {
    ...mapState({
      oho_member: (state) => state.oho_member.member || {},
      members: (state) => state.online_status.members || [],
    }),
    online_status_classname() {
      return this.getOnlineStatusClassname(this.current_status)
    },
  },
  methods: {
    ...mapActions(["updateMemberOnlineStatus"]),
    getOnlineStatusClassname(status) {
      if (status === "online") {
        return "is-online"
      } else if (status === "away") {
        return "is-away"
      } else {
        return "is-offline"
      }
    },
    async onChangeStatus(value) {
      try {
        await this.$ohoMemberApi.$patch(`${ep.member}/${this.oho_member._id}`, {
          online_status: value,
        })

        this.updateMemberOnlineStatus({
          member_id: this.oho_member._id,
          online_status: value,
        })
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    },
    findMemberOnlineStatus() {
      const member = _.find(this.members, {
        _id: this.oho_member._id,
      })
      const online_status = _.get(member, "online_status") || "offline"
      this.current_status = online_status
    },
  },
}
