import _ from "lodash"

const state = () => ({
  new_data: {
    name: null,
    messages: [],
    related_teams: [],
    all_trigger_out_set: true,
    incomplete_carousel: false,
  },
  arp_selected: null,
  edit_data: null,
  default_data: null,
  message_temp_id: null,
  carousel_temp_id: null,
  carousel_action_temp_id: null,
  current_step: "idle",
  prev_step: null,
  step_history: [{ current: "idle", prev: null }],
  total_saved_reply: 0,
  start_editing: false,
  max_message: 5,
})

const mutations = {
  setStartEditingSavedReply(state, boolean) {
    state.start_editing = boolean
  },
  setTotalSevedReply(state, data) {
    state.total_saved_reply = data
  },
  resetSavedReplyCurrentStep(state) {
    state.current_step = "idle"
    state.step_history = [{ current: "idle", prev: null }]
  },
  setSavedReplyNextStep(state, data) {
    state.step_history = [
      ...state.step_history,
      { current: data, prev: state.current_step },
    ]
    state.current_step = data
  },
  setSavedReplyBackStep(state, step_count = 1) {
    const last_step_history =
      step_count > 1
        ? _.nth(state.step_history, -step_count)
        : _.last(state.step_history)
    state.prev_step = _.get(last_step_history, "current")
    state.current_step = _.get(last_step_history, "prev")
    state.step_history =
      step_count > 1
        ? _.slice(state.step_history, -step_count)
        : _.initial(state.step_history)
  },
  setAutoReplySelected(state, value) {
    state.arp_selected = value
  },
  // id management
  setSavedReplyMessageTempId(state, data) {
    state.message_temp_id = data
  },
  setSavedReplyCarouselTempId(state, data) {
    state.carousel_temp_id = data
  },
  setSavedReplyCarouselActionTempId(state, data) {
    state.carousel_action_temp_id = data
  },
  resetSavedReplyMessageTempId(state) {
    state.message_temp_id = null
  },
  resetSavedReplyCarouselTempId(state) {
    state.carousel_temp_id = null
  },
  resetSavedReplyCarouselActionTempId(state) {
    state.carousel_action_temp_id = null
  },
  // defualt data management
  setDefaultSavedReply(state, data) {
    state.default_data = data
  },
  resetDefaultSavedReply(state) {
    state.default_data = null
  },

  // new data management
  resetNewSavedReply(state) {
    state.new_data = {
      name: null,
      messages: [],
      related_teams: [],
      all_trigger_out_set: true,
    }
  },
  setNewSavedReplyName(state, value) {
    state.new_data.name = value
  },
  setNewSavedReplyRelatedTeams(state, value) {
    state.new_data.related_teams = value
  },
  setNewSavedReplyMessages(state, value) {
    state.new_data.messages = value
  },
  setNewSavedReplyTriggersOut(state, value) {
    state.new_data.all_trigger_out_set = value
  },
  setNewSavedReplyIncompleteCarousel(state, value) {
    state.new_data.incomplete_carousel = value
  },

  // edit data management
  setEditSavedReply(state, data) {
    state.edit_data = data
  },
  resetEditSavedReply(state) {
    state.edit_data = {
      _id: "",
      name: null,
      messages: [],
      related_teams: [],
      all_trigger_out_set: true,
    }
  },
  setEditSavedReplyName(state, value) {
    state.edit_data.name = value
  },
  setEditSavedReplyRelatedTeams(state, value) {
    state.edit_data.related_teams = value
  },
  setEditSavedReplyMessages(state, value) {
    state.edit_data.messages = value
  },
  setEditSavedTriggersOut(state, value) {
    state.edit_data.all_trigger_out_set = value
  },
  setEditSavedReplyIncompleteCarousel(state, value) {
    state.edit_data.incomplete_carousel = value
  },
}

const actions = {
  backStepSavedReply({ commit }, step_count = 1) {
    commit("setSavedReplyBackStep", step_count)
  },
  nextStepSavedReply({ commit }, data) {
    commit("setSavedReplyNextStep", data)
  },
  setSavedReplyDefaultTempId({ commit }) {
    commit(
      "setSavedReplyMessageTempId",
      this.$broadcastHelper.generateDefaultTempId("message")
    )
    commit(
      "setSavedReplyCarouselTempId",
      this.$broadcastHelper.generateDefaultTempId("carousel")
    )
    commit(
      "setSavedReplyCarouselActionTempId",
      this.$broadcastHelper.generateDefaultTempId("action")
    )
  },
  checkSavedReplyDataChange({ state, commit }, current_data = {}) {
    const available_default_data = _.omit(state.default_data, ["_id"])
    const available_current_data = _.omit(current_data, ["_id"])
    if (
      !_.isEqual(available_default_data, available_current_data) &&
      !state.start_editing
    ) {
      commit("setStartEditingSavedReply", true)
    }
  },
  setAvailableTempId({ commit, state }, { type, used_id, available_id }) {
    if (type === "message") {
      commit("setSavedReplyMessageTempId", {
        available: available_id,
        used: [...state.message_temp_id.used, used_id],
      })
    } else if (type === "carousel") {
      commit("setSavedReplyCarouselTempId", {
        available: available_id,
        used: [...state.carousel_temp_id.used, used_id],
      })
    } else if (type === "action") {
      commit("setSavedReplyCarouselActionTempId", {
        available: available_id,
        used: [...state.carousel_action_temp_id.used, used_id],
      })
    }
  },
}

const getters = {
  total_saved_reply_edit_message: (state) => {
    return (state.edit_data.messages || []).length
  },
  total_saved_reply_new_message: (state) => {
    return (state.new_data.messages || []).length
  },
  saved_reply_available_message_id(state) {
    const { available } = state.message_temp_id
    const id = available.shift()
    return { id: id, available: available }
  },
  saved_reply_available_carousel_id(state) {
    const { available } = state.carousel_temp_id
    const id = available.shift()
    return { id: id, available: available }
  },
  saved_reply_available_carousel_action_id(state) {
    const { available } = state.carousel_action_temp_id
    const id = available.shift()
    return { id: id, available: available }
  },
}

export default { state, mutations, getters, actions }
