
export default {
  props: {
    user_type: {
      type: String,
      default: "bot",
    },
    user_id: {
      type: String,
      default: "",
    },
    user_img: {
      type: String,
      default: "",
    },
    user_platform: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      image_error: false,
      is_thumbnail: false,
      user_placeholder_image: require("~/assets/images/Property 1=Empty.png"),
      bot_placeholder_image: require("~/assets/images/bot_profile_image.png"),
      broadcast_placeholder_image: require("~/assets/images/broadcast_profile_image.svg"),
      facebook_placeholder_image: require("~/assets/images/facebook_logo_circle.png"),
      line_placeholder_image: require("~/assets/images/icon-line-oa.svg"),
      instagram_placeholder_image: require("~/assets/images/icon-instagram.svg"),
    }
  },
  computed: {
    display_image() {
      let image
      switch (this.user_type) {
        case "bot":
          image = this.bot_placeholder_image
          break

        case "broadcast":
          image = this.broadcast_placeholder_image
          break

        case "agent-inbox":
          image =
            this.user_platform === "facebook"
              ? this.facebook_placeholder_image
              : this.user_platform === "line"
              ? this.line_placeholder_image
              : this.instagram_placeholder_image
          break

        default:
          // Use thumbnail image of original image first
          image = this.$thumbnailHelper.replaceImageUrl(this.user_img)
          this.is_thumbnail = true
          break
      }
      return image
    },
  },
  methods: {
    replaceByDefault(e) {
      if (this.is_thumbnail) {
        // If cannot fetch thumbnail image, use original image
        e.target.src = this.user_img
        this.is_thumbnail = false
      } else {
        // If cannot fetch original image, show placeholder image
        e.target.src = this.user_placeholder_image
      }
    },
    // getRandomIndex() {
    //   let res = 0;
    //   for (let i = 0; i < this.user_id.length; i++) {
    //     res += this.user_id.charCodeAt(i) - 97;
    //   }
    //   const mod = this.user_type == "user" ? 9 : 2;
    //   return Math.abs(res % mod);
    // },
    // getImage() {
    //   const index = this.getRandomIndex();
    //   if (this.user_type === "bot") {
    //     return this.bot_placeholder_image;
    //   }
    //   if (this.user_type === "user") {
    //     return this.user_img || this.user_placeholder_image;
    //   }
    //   return this.user_img || this.member_placeholders[index];
    // },
    // getAvatar() {
    //   const index = this.getRandomIndex();
    //   return this.user_placeholders[index];
    // },
  },
}
