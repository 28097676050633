
import moment from "moment"

export default {
  props: {
    start_date: {
      type: String,
      default: "",
    },
    end_date: {
      type: String,
      default: "",
    },
  },
  computed: {
    formatTime() {
      const diff = moment(this.end_date).diff(moment(this.start_date))
      const hours = Math.floor(moment.duration(diff).asHours())
      if (hours > 0) {
        const time = this.$formatDate.minuteHourDiffTime(diff)
        return `${hours}:${time}`
      } else {
        return this.$formatDate.fullDiffTime(diff)
      }
    },
  },
}
