
/**
 * The v-viewer is used for image viewing.
 * We added this library to support the zoom feature.
 * First, before the component is mounted,
 * check the file type to ensure it is an image.
 * Then, initialize the v-viewer by pass an image URL.
 * after that, the v-viewer will render the image using its modal.
 */
import "viewerjs/dist/viewer.css"
import { api as viewerApi } from "v-viewer"

export default {
  props: {
    source_url: {
      type: String,
      default: "",
    },
    thumbnail_url: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "image",
    },
    hideModal: {
      type: Function,
      default: () => {},
    },
    sender_name: {
      type: String,
      default: "",
      require: false,
    },
    display_date: {
      type: String,
      default: "",
      require: false,
    },
    disable_btn_dowload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image_error: false,
      video_error: false,
      not_support_media_placeholder_url: require("~/assets/images/not_support_media_placeholder.svg"),
      show_tooltip: false,
      event_hover: {},
      hover_text: "",
    }
  },
  beforeMount() {
    if (this.type === "image") {
      viewerApi({
        images: [this.source_url],
        options: {
          title: [
            1,
            () => {
              if (this.is_conversation) {
                return `${this.sender_name} ${this.display_date}`
              } else {
                return this.file_name
              }
            },
          ],
          navbar: false,
          transition: false,
          toolbar: {
            zoomIn: true,
            zoomOut: true,
            reset: true,
            rotateLeft: true,
            rotateRight: true,
            play: false,
            download: () => {
              if (this.disable_btn_dowload) return
              this.downloadImage()
            },
          },
          ready: () => {
            const zoom_in_btn = document.querySelector(".viewer-zoom-in")
            const zoom_out_btn = document.querySelector(".viewer-zoom-out")
            const reset_btn = document.querySelector(".viewer-reset")
            const rotate_left_btn = document.querySelector(
              ".viewer-rotate-left"
            )
            const rotate_right_btn = document.querySelector(
              ".viewer-rotate-right"
            )
            const download_btn = document.querySelector(".viewer-download")

            zoom_in_btn.addEventListener("mouseover", (e) =>
              this.onHoverZoomToolbarBtn(e, "ซูมเข้า")
            )
            zoom_out_btn.addEventListener("mouseover", (e) =>
              this.onHoverZoomToolbarBtn(e, "ซูมออก")
            )
            reset_btn.addEventListener("mouseover", (e) =>
              this.onHoverZoomToolbarBtn(e, "รีเซ็ต")
            )
            rotate_left_btn.addEventListener("mouseover", (e) =>
              this.onHoverZoomToolbarBtn(e, "หมุนไปทางซ้าย")
            )
            rotate_right_btn.addEventListener("mouseover", (e) =>
              this.onHoverZoomToolbarBtn(e, "หมุนไปทางขวา")
            )
            download_btn.addEventListener("mouseover", (e) =>
              this.onHoverZoomToolbarBtn(e, "ดาวน์โหลด")
            )

            zoom_in_btn.addEventListener("mouseleave", () =>
              this.onHideTooltip()
            )
            zoom_out_btn.addEventListener("mouseleave", () =>
              this.onHideTooltip()
            )
            reset_btn.addEventListener("mouseleave", () => this.onHideTooltip())
            rotate_left_btn.addEventListener("mouseleave", () =>
              this.onHideTooltip()
            )
            rotate_right_btn.addEventListener("mouseleave", () =>
              this.onHideTooltip()
            )
            download_btn.addEventListener("mouseleave", () =>
              this.onHideTooltip()
            )

            if (download_btn && this.disable_btn_dowload) {
              download_btn.style["cursor"] = "default"
              download_btn.style["opacity"] = "0.5"
              download_btn.addEventListener("mouseover", (e) =>
                this.onHoverZoomToolbarBtn(
                  e,
                  "ไม่สามารถดาวน์โหลดไฟล์ เนื่องจากเงื่อนไขของ Instagram"
                )
              )
            }
          },
          hide: () => {
            this.hideModal()
          },
        },
      })
    }
  },
  computed: {
    file_name() {
      if (this.name) return this.name

      if (this.type === "image") {
        const file_name = this.source_url.match(
          /[\w\.\$\-]+(?=png|jpg|jpeg|gif)\w+/gi
        )
        return _.get(file_name, "[0]", `${this.source_url}`)
      }

      return this.source_url
    },
    is_conversation() {
      const senderNameIsEmpty = this.sender_name.length === 0
      const displayDateIsEmpty = this.display_date.length === 0
      if (senderNameIsEmpty || displayDateIsEmpty) return false
      else return true
    },
    media_library_download_permission() {
      return this.$permission.validate("media-library.download")
    },
  },
  methods: {
    async onHoverZoomToolbarBtn(e, text) {
      const src_ele = _.get(e, "srcElement")
      if (!src_ele) return

      this.hover_text = text
      await this.$nextTick()

      const src_ele_position = src_ele.getBoundingClientRect()
      const tooltip_height = this.$refs.zoom_tooltip.clientHeight
      const tooltip_width = this.$refs.zoom_tooltip.clientWidth

      this.event_hover = {
        top: src_ele_position.top - tooltip_height - 12,
        left:
          src_ele_position.left + src_ele.clientWidth / 2 - tooltip_width / 2,
      }
      this.show_tooltip = true
    },
    onHideTooltip() {
      this.show_tooltip = false
    },
    imageHandler() {
      this.image_error = true
    },
    videoHandler() {
      this.video_error = true
    },
    async downloadImage() {
      try {
        const image = await fetch(this.source_url, {
          mode: "cors",
        })
        var imageBlob = await image.blob()
      } catch (error) {
        this.$logger.error(
          "ModalExpandFile > Image loading failed",
          error.message
        )
      }
      const imageURL = URL.createObjectURL(imageBlob)
      const link = document.createElement("a")
      link.href = imageURL
      link.download = this.file_name
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(imageURL)
          link.removeEventListener("click", clickHandler)
        }, 500)
      }
      link.addEventListener("click", clickHandler, false)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
