
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    user_type: {
      type: String,
      default: "user",
    },
    teams: {
      type: Array,
      default: () => [],
    },
    show_role: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show_team() {
      return this.teams.length > 0
    },
  },
}
