
import { mapState, mapGetters, mapMutations } from "vuex"
import _ from "lodash"
import ep from "~/api/endpoint"
import Vue from "vue"
import Clipboard from "v-clipboard"

Vue.use(Clipboard)
export default {
  data() {
    return {
      copy_clipboard: false,
      has_request: false,
      // checked_channels: [],
      all_channel: true,
      channel_dropdown: false,
      isIndeterminate: false,
    }
  },
  computed: {
    ...mapState({
      business: (state) => state.business || {},
      oho_member: (state) => state.oho_member,
      channels: (state) => state.channels,
    }),
    ...mapGetters(["nav_menu"]),
    checked_channels: {
      set(val) {
        this.setCheckedChannels(val)
      },
      get() {
        return this.$store.state.checked_channels
      },
    },
    current_menu() {
      const menu = this.nav_menu.find((m) => m.path_id === this.path_name)
      return menu || {}
    },
    member() {
      return _.get(this.oho_member, "member") || {}
    },
    path_name() {
      return this.$route.path.split("/")[3]
    },
    current_sub_menu() {
      let current_menu = null
      let current_child_sub_menu = null
      const sub_menu_list = _.get(this.current_menu, "sub_menu_list") || []
      for (let index = 0; index < sub_menu_list.length; index++) {
        const sub_menu = sub_menu_list[index]
        current_menu = sub_menu.childs.find((child_menu) => {
          const child_params = new URLSearchParams(
            child_menu.path.split("?")[1]
          )
          const route_params = new URLSearchParams(
            this.$route.fullPath.split("?")[1]
          )
          const assignee = route_params.get("assignee")
          if (assignee) {
            if (child_menu.sub_menu) {
              current_child_sub_menu = _.find(child_menu.sub_menu, {
                id: assignee,
              })
              return current_child_sub_menu
            } else {
              return false
            }
          } else {
            return route_params.get("status") === child_params.get("status")
          }
        })
        if (current_menu) break
      }
      return current_child_sub_menu
        ? current_child_sub_menu
        : current_menu || {}
    },
  },
  async created() {
    // if (["media-library", "setting"].includes(this.path_name)) {
    //   this.show_filter_channel = false
    // }
    // await this.CountOtherBusinessRequest()
  },
  methods: {
    ...mapMutations(["setCheckedChannels"]),
    errorHandler() {
      return this.business.image_url ? false : true
    },
    clickCopyClipboard() {
      this.copy_clipboard = true
      setTimeout(() => {
        this.copy_clipboard = false
      }, 3000)
    },
    // async CountOtherBusinessRequest() {
    //   try {
    //     const res = await this.$ohoApi.$get(ep.my_member_list, {
    //       params: {
    //         $limit: 50,
    //         user_id: this.$cookies.get("oho_user_id"),
    //       },
    //     })
    //     const my_member_list = _.get(res, "data")
    //     if (my_member_list.length > 1) {
    //       const my_member_list_expected_current = my_member_list.filter(
    //         (member) => member.business_id._id !== this.business._id
    //       )
    //       const other_biz_has_request = _.find(
    //         my_member_list_expected_current,
    //         "request_status"
    //       )
    //       this.has_request = other_biz_has_request ? true : false
    //     }
    //   } catch (error) {
    //     // this.$message.error(_.get(error, "response.data"))
    //   }
    // },
    handleChannelSelectOnly(channel) {
      this.checked_channels = [channel]
      this.all_channel = false
      this.isIndeterminate = true
    },
    configureChecked(channel = {}) {
      let disable = false
      const { length } = this.checked_channels
      const findIndex = this.checked_channels.findIndex((d) => d == channel._id)
      if (length == 1 && findIndex > -1) disable = true
      return disable
    },
    handleCheckAllChange(val) {
      if (val) {
        this.checked_channels = this.channels.map((d) => d._id)
        this.isIndeterminate = false
      } else {
        const frist = _.get(_.head(this.channels), "_id")
        this.checked_channels = [frist]
        this.isIndeterminate = true
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.all_channel = checkedCount === this.channels.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.channels.length
    },
  },
}
