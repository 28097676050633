
import error404 from "~/components/Error/404.vue"

export default {
  name: "nuxt-error",
  layout: "no-auth", // optional
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return error404
      }
      // catch everything else
      return error500
    },
  },
}
