
import _ from "lodash"
import ep from "~/api/endpoint"
import { mapState, mapMutations } from "vuex"

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    hideDrawer: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      my_member_list: [],
      fullscreenLoading: false,
      // current_business: { id: "", name: "" },
      visible_drawer: false,
      show_create_biz_dialog: false,
      show_close_add_business: false,
      is_create_biz_filled: false,
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      business: (state) => state.business || {},
    }),
    drawer_style() {
      const alert_length = _.get(this.alert, "length", 0)
      const is_mobile = _.get(this.$userAgent, "is_mobile", false)
      return alert_length > 0 && !is_mobile ? `top: ${40 * alert_length}px` : ""
    },
  },
  watch: {
    drawer(newValue) {
      this.visible_drawer = newValue
    },
  },
  created() {
    // this.getBusiness()
    this.getBusinessList()
  },
  methods: {
    ...mapMutations(["setMyMemberList"]),
    checkAddBussinessFilled(isFilled) {
      this.is_create_biz_filled = isFilled
    },
    hasClosed() {
      if (this.is_create_biz_filled) {
        this.show_close_add_business = true
      } else {
        this.show_create_biz_dialog = false
      }
    },
    closeAddBusinessDialog() {
      this.show_create_biz_dialog = false
      this.show_close_add_business = false
      this.is_create_biz_filled = false
    },
    viewAllBusiness() {
      this.$cookies.remove("oho_current_biz", {
        path: "/",
      })
      window.location.replace("/select-business")
    },
    errorHandler() {
      return this.user_img ? false : true
    },
    // async getBusiness() {
    //   try {
    //     this.business_id = this.$cookies.get("oho_current_biz")
    //     const business = await this.$ohoMemberApi.$get(
    //       `${ep.business}/${this.business_id}`
    //     )
    //     this.current_business.id = business._id
    //     this.current_business.name = business.name
    //   } catch (error) {
    //     this.$message.error(_.get(error, "response.data"))
    //   }
    // },
    async getBusinessList() {
      try {
        const res = await this.$ohoApi.$get(ep.my_member_list, {
          params: {
            $limit: 100,
            user_id: this.$cookies.get("oho_user_id"),
          },
        })

        const my_member_list = _.chain(res)
          .get("data")
          .orderBy(["request_status", "business_id.name"], ["desc", "asc"])
          .value()

        this.my_member_list = my_member_list
        this.setMyMemberList(my_member_list)
      } catch (error) {
        // this.$message.error(_.get(error, "response.data"))
      }
    },
    switchBusiness(id) {
      if (id === this.business._id) return
      this.$cookies.set("oho_current_biz", id, {
        path: "/",
      })
      this.fullscreenLoading = true
      // [TODO : Nick] ปรับ default หน้า smartchat ให้เป็นหน้าแชทของคุณชั่วคราว
      // window.location.replace(`/business/${id}/smartchat`)
      window.location.replace(`/business/${id}/smartchat?status=me`)
    },
    addBusiness() {
      this.show_create_biz_dialog = true
      // this.hideDrawer()
      // if (process.client) {
      //   window.open(`/add-business`)
      // }
    },
  },
}
