import _ from "lodash"
import ep from "~/api/endpoint"

const state = () => ({
  new_data: {
    name: null,
    messages: [],
    audience_type: "all",
    selected_audience_group: [],
    selected_channels: [],
    schedule_type: "immediately",
    schedule: { date: null, time: null },
    tags: [],
    indicator: {
      valid_unique_name: true,
      valid_name: true,
      valid_channel: true,
      valid_audience: true,
      valid_schedule: true,
      valid_messages: true,
      valid_all_trigger_out: true,
      valid_carousel: true,
    },
    failure: {
      channel: false,
      audience: false,
      schedule: false,
      messages: false,
      trigger_out: false,
      carousel: false,
    },
  },
  presets: [],
  channels: [],
  edit_data: {
    name: null,
    messages: [],
    audience_type: "all",
    selected_audience_group: [],
    selected_channels: [],
    schedule_type: "immediately",
    schedule: { date: null, time: null },
    tags: [],
    indicator: {
      valid_unique_name: true,
      valid_name: true,
      valid_channel: true,
      valid_audience: true,
      valid_schedule: true,
      valid_messages: true,
      valid_all_trigger_out: true,
      valid_carousel: true,
    },
    failure: {
      channel: false,
      audience: false,
      schedule: false,
      messages: false,
      trigger_out: false,
      carousel: false,
    },
  },
  default_data: null,
  message_temp_id: null,
  carousel_temp_id: null,
  carousel_action_temp_id: null,
  previous_page: "",
  start_editing: false,
})

const mutations = {
  setBroadcastPresets(state, data) {
    state.presets = data
  },
  setBroadcastChannels(state, data) {
    state.channels = data
  },
  setBroadcastPreviousPage(state, data) {
    state.previous_page = data
  },
  resetBroadcastPreviousPage(state, data) {
    state.previous_page = ""
  },
  // id management
  setBroadcastMessageTempId(state, data) {
    state.message_temp_id = data
  },
  setBroadcastCarouselTempId(state, data) {
    state.carousel_temp_id = data
  },
  setBroadcastCarouselActionTempId(state, data) {
    state.carousel_action_temp_id = data
  },
  resetBroadcastMessageTempId(state) {
    state.message_temp_id = null
  },
  resetBroadcastCarouselTempId(state) {
    state.carousel_temp_id = null
  },
  resetBroadcastCarouselActionTempId(state) {
    state.carousel_action_temp_id = null
  },
  // edit data management
  setEditBroadcast(state, data) {
    state.edit_data = data
  },
  resetEditBroadcast(state) {
    state.edit_data = {
      name: null,
      messages: [],
      audience_type: "all",
      selected_audience_group: [],
      selected_channels: [],
      schedule_type: "immediately",
      schedule: { date: null, time: null },
      tags: [],
      indicator: {
        valid_unique_name: true,
        valid_name: true,
        valid_channel: true,
        valid_audience: true,
        valid_schedule: true,
        valid_messages: true,
        valid_all_trigger_out: true,
        valid_carousel: true,
      },
      failure: {
        channel: false,
        audience: false,
        schedule: false,
        messages: false,
        trigger_out: false,
        carousel: false,
      },
    }
  },
  setEditBroadcastName(state, value) {
    state.edit_data.name = value
  },
  setEditBroadcastTags(state, value) {
    state.edit_data.tags = value
  },
  setEditBroadcastMessages(state, value) {
    state.edit_data.messages = value
  },
  setEditBroadcastValidTriggersOut(state, boolean) {
    state.edit_data.indicator.valid_all_trigger_out = boolean
  },
  setEditBroadcastValidCarousel(state, boolean) {
    state.edit_data.indicator.valid_carousel = boolean
  },
  setEditBroadcastValidMessages(state, boolean) {
    state.edit_data.indicator.valid_messages = boolean
  },
  setEditBroadcastValidUniqueName(state, boolean) {
    state.edit_data.indicator.valid_unique_name = boolean
  },
  setEditBroadcastValidChannel(state, boolean) {
    state.edit_data.indicator.valid_channel = boolean
  },
  setEditBroadcastValidAudience(state, boolean) {
    state.edit_data.indicator.valid_audience = boolean
  },
  setEditBroadcastValidName(state, boolean) {
    state.edit_data.indicator.valid_name = boolean
  },
  setEditBroadcastValidSchedule(state, boolean) {
    state.edit_data.indicator.valid_schedule = boolean
  },
  setEditBroadcastCarouselIsFail(state, boolean) {
    state.edit_data.failure.carousel = boolean
  },
  setEditBroadcastMessageIsFail(state, boolean) {
    state.edit_data.failure.messages = boolean
  },
  setEditBroadcastTriggersOutIsFail(state, boolean) {
    state.edit_data.failure.trigger_out = boolean
  },
  setEditBroadcastChannelIsFail(state, boolean) {
    state.edit_data.failure.channel = boolean
  },
  setEditBroadcastAudienceIsFail(state, boolean) {
    state.edit_data.failure.audience = boolean
  },
  setEditBroadcastScheduleIsFail(state, boolean) {
    state.edit_data.failure.schedule = boolean
  },
  setEditBroadcastSchedule(state, data) {
    state.edit_data.schedule = data
  },
  setEditBroadcastTime(state, data) {
    state.edit_data.schedule_type = data
  },
  setEditBroadcastAudienceType(state, data) {
    state.edit_data.audience_type = data
  },
  setEditBroadcastSelectedAudienceGroup(state, data) {
    state.edit_data.selected_audience_group = data
  },
  setEditBroadcastSelectedChannels(state, data) {
    state.edit_data.selected_channels = data
  },
  setEditBroadcastIndicator(state, data) {
    state.edit_data.indicator = data
  },

  // defualt data management
  resetDefaultBroadcastData(state) {
    state.default_data = null
  },
  setDefaultBroadcastData(state, data) {
    state.default_data = data
  },

  // new data management
  setNewBroadcast(state, value) {
    state.new_data = value
  },
  resetNewBroadcast(state) {
    state.new_data = {
      name: null,
      messages: [],
      audience_type: "all",
      selected_audience_group: [],
      selected_channels: [],
      schedule_type: "immediately",
      schedule: { date: null, time: null },
      tags: [],
      indicator: {
        valid_unique_name: true,
        valid_name: true,
        valid_channel: true,
        valid_audience: true,
        valid_schedule: true,
        valid_messages: true,
        valid_all_trigger_out: true,
        valid_carousel: true,
      },
      failure: {
        channel: false,
        audience: false,
        schedule: false,
        messages: false,
        trigger_out: false,
        carousel: false,
      },
    }
  },
  setNewBroadcastName(state, value) {
    state.new_data.name = value
  },
  setNewBroadcastTags(state, value) {
    state.new_data.tags = value
  },
  setNewBroadcastMessages(state, value) {
    state.new_data.messages = value
  },
  setNewBroadcastValidTriggersOut(state, boolean) {
    state.new_data.indicator.valid_all_trigger_out = boolean
  },
  setNewBroadcastValidCarousel(state, boolean) {
    state.new_data.indicator.valid_carousel = boolean
  },
  setNewBroadcastValidMessages(state, boolean) {
    state.new_data.indicator.valid_messages = boolean
  },
  setNewBroadcastValidUniqueName(state, boolean) {
    state.new_data.indicator.valid_unique_name = boolean
  },
  setNewBroadcastValidChannel(state, boolean) {
    state.new_data.indicator.valid_channel = boolean
  },
  setNewBroadcastValidAudience(state, boolean) {
    state.new_data.indicator.valid_audience = boolean
  },
  setNewBroadcastValidName(state, boolean) {
    state.new_data.indicator.valid_name = boolean
  },
  setNewBroadcastValidSchedule(state, boolean) {
    state.new_data.indicator.valid_schedule = boolean
  },
  setNewBroadcastCarouselIsFail(state, boolean) {
    state.new_data.failure.carousel = boolean
  },
  setNewBroadcastMessageIsFail(state, boolean) {
    state.new_data.failure.messages = boolean
  },
  setNewBroadcastTriggersOutIsFail(state, boolean) {
    state.new_data.failure.trigger_out = boolean
  },
  setNewBroadcastChannelIsFail(state, boolean) {
    state.new_data.failure.channel = boolean
  },
  setNewBroadcastAudienceIsFail(state, boolean) {
    state.new_data.failure.audience = boolean
  },
  setNewBroadcastScheduleIsFail(state, boolean) {
    state.new_data.failure.schedule = boolean
  },
  setNewBroadcastSchedule(state, data) {
    state.new_data.schedule = data
  },
  setNewBroadcastTime(state, data) {
    state.new_data.schedule_type = data
  },
  setNewBroadcastAudienceType(state, data) {
    state.new_data.audience_type = data
  },
  setNewBroadcastSelectedAudienceGroup(state, data) {
    state.new_data.selected_audience_group = data
  },
  setNewBroadcastSelectedChannels(state, data) {
    state.new_data.selected_channels = data
  },
  setNewBroadcastIndicator(state, data) {
    state.new_data.indicator = data
  },
  setStartEditingBroadcast(state, boolean) {
    state.start_editing = boolean
  },
}

const actions = {
  setBroadcastFailure({ state, commit }, type = "create") {
    const current_indicator =
      type === "edit"
        ? _.get(state, "edit_data.indicator")
        : _.get(state, "new_data.indicator")
    if (!current_indicator.valid_carousel) {
      if (type === "edit") commit("setEditBroadcastCarouselIsFail", true)
      else commit("setNewBroadcastCarouselIsFail", true)
    }
    if (!current_indicator.valid_messages) {
      if (type === "edit") commit("setEditBroadcastMessageIsFail", true)
      else commit("setNewBroadcastMessageIsFail", true)
    }
    if (!current_indicator.valid_all_trigger_out) {
      if (type === "edit") commit("setEditBroadcastTriggersOutIsFail", true)
      else commit("setNewBroadcastTriggersOutIsFail", true)
    }
    if (!current_indicator.valid_channel) {
      if (type === "edit") commit("setEditBroadcastChannelIsFail", true)
      else commit("setNewBroadcastChannelIsFail", true)
    }
    if (!current_indicator.valid_audience) {
      if (type === "edit") commit("setEditBroadcastAudienceIsFail", true)
      else commit("setNewBroadcastAudienceIsFail", true)
    }
    if (!current_indicator.valid_schedule) {
      if (type === "edit") commit("setEditBroadcastScheduleIsFail", true)
      else commit("setNewBroadcastScheduleIsFail", true)
    }
  },
  checkBroadcastDataChange({ state, commit }, current_data = {}) {
    const available_default_data = _.omit(state.default_data, [
      "indicator",
      "_id",
      "status",
      "failure",
    ])
    const available_current_data = _.omit(current_data, [
      "indicator",
      "_id",
      "status",
      "failure",
    ])
    if (
      !_.isEqual(available_default_data, available_current_data) &&
      !state.start_editing
    ) {
      commit("setStartEditingBroadcast", true)
    }
  },
  setBroadcastDefaultTempId({ commit }) {
    commit(
      "setBroadcastMessageTempId",
      this.$broadcastHelper.generateDefaultTempId("message")
    )
    commit(
      "setBroadcastCarouselTempId",
      this.$broadcastHelper.generateDefaultTempId("carousel")
    )
    commit(
      "setBroadcastCarouselActionTempId",
      this.$broadcastHelper.generateDefaultTempId("action")
    )
  },
  updateBroadcastPresets({ state, commit }, selected_audience_group = []) {
    if (!_.isEmpty(selected_audience_group)) {
      const current_presets = state.presets
      const current_presets_ids = _.map(current_presets, "_id")
      const diff_preset_ids = _.difference(
        selected_audience_group,
        current_presets_ids
      )
      const union_preset_ids = _.union(
        current_presets_ids,
        selected_audience_group
      )
      const tranform_selected_audience_group = _.map(
        union_preset_ids,
        (val) => {
          if (_.includes(diff_preset_ids, val))
            return {
              _id: `empty-preset-${val}`,
              name: null,
            }
          else {
            return _.find(current_presets, ["_id", val])
          }
        }
      )
      commit("setBroadcastPresets", tranform_selected_audience_group)
    } else {
      commit("setBroadcastPresets", state.presets)
    }
  },
  updateBroadcastChannels({ commit, rootState }, selected_channels = []) {
    const available_channels = _.reject(rootState.channels, [
      "platform",
      "instagram",
    ])
    if (!_.isEmpty(selected_channels)) {
      const root_channel_ids = _.map(available_channels, "_id")
      const diff_channels_ids = _.difference(
        selected_channels,
        root_channel_ids
      )
      const union_channel_ids = _.union(root_channel_ids, selected_channels)
      const tranform_selected_channel = _.map(union_channel_ids, (val) => {
        if (_.includes(diff_channels_ids, val))
          return {
            _id: `empty-channel-${val}`,
            display_name: null,
          }
        else {
          const data = _.find(rootState.channels, ["_id", val])
          return {
            ...data,
            broadcast_selected: _.includes(selected_channels, val),
          }
        }
      })
      commit("setBroadcastChannels", tranform_selected_channel)
    } else {
      commit("setBroadcastChannels", available_channels)
    }
  },
  setBroadcastAggregate({ commit, rootState }, agg) {
    const menu_list = _.get(rootState, "menu.menu_list")
    const index = menu_list.findIndex((m) => m.path === "/broadcast")
    const menu = _.cloneDeep(menu_list[index])
    menu.sub_menu_list.forEach((s) => {
      s.childs.forEach((c) => {
        if (c.name === "ทั้งหมด") {
          c.total = _.get(agg, "all", 0)
        } else if (c.name === "แบบร่าง") {
          c.total = _.get(agg, "draft", 0)
        } else if (c.name === "ตั้งเวลาส่ง") {
          c.total = _.get(agg, "scheduled", 0)
        } else if (c.name === "ส่งสำเร็จ") {
          const sending_count = _.get(agg, "sending", 0)
          const success_count = _.get(agg, "success", 0)
          c.total = sending_count + success_count
        } else if (c.name === "ส่งไม่สำเร็จ") {
          c.total = _.get(agg, "failed", 0)
        }
      })
    })
    commit("setMenu", { index, menu })
  },
  async getBraodcastAggregate({ dispatch }) {
    try {
      const agg_value_by_status = await this.$ohoMemberApi.$get(
        `${ep.broadcast}/aggregate`
      )
      await dispatch("setBroadcastAggregate", agg_value_by_status)
    } catch (error) {
      this.$logger.error(error)
    }
  },
  async getBroadcastPresets({ commit }) {
    try {
      const res = await this.$ohoMemberApi.$get(ep.contact_preset_search, {
        params: {
          $limit: 100,
          "$sort[order_index]": 1,
          "$populate[path]": "conditions.tags",
        },
      })
      const data = _.get(res, "data", [])
      if (data.length === 0) return
      await commit("setBroadcastPresets", data)
    } catch (error) {
      this.$logger.error(error)
    }
  },
}

const getters = {
  total_broadcast_edit_message: (state) => {
    return (state.edit_data.messages || []).length
  },
  total_broadcast_new_message: (state) => {
    return (state.new_data.messages || []).length
  },
  broadcast_available_message_id(state) {
    const { available } = state.message_temp_id
    const id = available.shift()
    return { id: id, available: available }
  },
  broadcast_available_carousel_id(state) {
    const { available } = state.carousel_temp_id
    const id = available.shift()
    return { id: id, available: available }
  },
  broadcast_available_carousel_action_id(state) {
    const { available } = state.carousel_action_temp_id
    const id = available.shift()
    return { id: id, available: available }
  },
}

export default { state, mutations, getters, actions }
