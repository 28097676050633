
import { mapState } from "vuex"

export default {
  data() {
    return {
      set_selected_team: this.selected_team ? [...this.selected_team] : [],
      join_teams: [],
      leave_teams: [],
      rules: {
        display_name: [
          {
            required: true,
            message: "โปรดระบุชื่อ",
            trigger: "blur",
          },
        ],
      },
    }
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    selected_team: {
      type: Array,
      default: () => [],
    },
    form_item: {
      type: Array,
      default: () => [],
    },
    is_role: {
      type: Boolean,
      default: false,
    },
    label_width: {
      type: String,
      default: "105px",
    },
    is_profile: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onUpdateTeam(teamList) {
      const default_team_data = this.selected_team

      this.join_teams = teamList
        .filter(
          (team) =>
            !default_team_data.some(
              (defaultTeam) => defaultTeam._id === team._id
            )
        )
        .map((team) => team._id)

      this.leave_teams = default_team_data
        .filter(
          (defaultTeam) =>
            !teamList.some((team) => team._id === defaultTeam._id)
        )
        .map((team) => team._id)

      this.set_selected_team = [...teamList]
      this.$emit("update-set-selected-teams", this.set_selected_team)
      this.$emit("update-join-teams", this.join_teams)
      this.$emit("update-leave-teams", this.leave_teams)
    },

    onRemoveTeam(teamId) {
      this.set_selected_team = this.set_selected_team.filter(
        (team) => team._id !== teamId
      )

      const index_join_teams = this.join_teams.indexOf(teamId)
      if (index_join_teams !== -1) {
        this.join_teams.splice(index_join_teams, 1)
      }
      this.leave_teams.push(teamId)
      this.$emit("update-set-selected-teams", this.set_selected_team)
      this.$emit("update-join-teams", this.join_teams)
      this.$emit("update-leave-teams", this.leave_teams)
    },
    validateField(fieldName) {
      return this.member[fieldName] !== ""
    },
    validateForm() {
      const isValid = this.validateField("display_name")
      return isValid
    },
    handleRoleDropdownVisibility(select_role) {
      if (this.member.role === select_role) {
        return true
      } else {
        return this.$permission.validate(
          `member.role.level-${select_role}.update`
        )
      }
    },
  },
  computed: {
    ...mapState({
      oho_member: (state) => state.oho_member.member,
      owners: (state) => state.owners,
    }),
    update_role_permission() {
      return this.$permission.validate("member.role.update")
    },
    team_member_create_permission() {
      return this.$permission.validate("team.member.create")
    },
    team_member_remove_permission() {
      return this.$permission.validate("team.member.remove")
    },
    team_is_member_update_permission() {
      return this.$permission.validate("team.me-is-member.remove")
    },
    team_is_not_member_update_permission() {
      return this.$permission.validate("team.me-is-not-member.update")
    },
    is_enabled_add_team_btn() {
      return this.team_member_create_permission
    },
    is_enabled_remove_team_btn() {
      return this.team_member_remove_permission
    },
    is_warn_remove_owner() {
      if (this.is_profile) {
        return this.owners <= 1 && this.member.role === "owner"
      } else {
        return false
      }
    },
  },
}
