
import { mapState } from "vuex"
import _ from "lodash"
export default {
  props: {
    platform: {
      type: Array,
      default: () => {
        return []
      },
    },
    maximum_channel: {
      type: Number,
      default: 0,
    },
    icon_border_radius: {
      type: String,
      default: "100%",
    },
    font_size: {
      type: String,
      default: "14.3343px",
    },
    font_weight: {
      type: String,
      default: "400",
    },
    additional_detail: {
      type: Boolean,
      default: true,
    },
    show_label: {
      type: Boolean,
      default: true,
    },
    custom_channels: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    channels() {
      return _.isEmpty(this.custom_channels)
        ? this.$store.state.channels
        : this.custom_channels
    },
    select_channel_icon() {
      const { length } = this.platform
      const icons = _.chain(this.channels)
        .filter((d) => this.platform.includes(d._id))
        .slice(0, 3)
        .reverse()
        .value()
      const num = _.chain(this.channels)
        .filter((d) => this.platform.includes(d._id))
        .slice(3, length)
        .value()
      return {
        icons,
        num: num.length,
      }
    },
    select_channel_label() {
      let text = ""
      const checked_num = this.platform.length
      const channels_num = this.maximum_channel || this.channels.length
      const channel_name = _.chain(this.channels)
        .find((d) => d._id == _.get(this.platform, "[0]"))
        .get("display_name")
        .value()

      if (!this.additional_detail) {
        return channel_name
      }

      if (checked_num == channels_num) {
        text = "ทุกช่องทาง"
      } else if (checked_num > 1) {
        text = `เลือกบางช่องทาง <span style="font-size: 10px; margin-left: 4px">(${checked_num}/${channels_num})</span>`
      } else if (checked_num == 1) {
        text = `เฉพาะ ${channel_name}`
      }

      return text
    },
  },
}
