
import _ from "lodash"
import { mapState, mapMutations } from "vuex"
import ep from "~/api/endpoint"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      show_dialog: false,
    }
  },
  watch: {
    visible: {
      handler(val) {
        this.show_dialog = val
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      alert_changes_role: (state) => state.alert_changes_role || {},
      oho_member: (state) => state.oho_member.member || {},
      oho_member_preferences: (state) => state.oho_member_preferences,
    }),
    display_name() {
      return _.get(this.alert_changes_role, "changed_by.display_name", "")
    },
    changed_role() {
      return this.$role.roleDisplayName(
        this.alert_changes_role.role || this.oho_member.role
      )
    },
    member_preferences_id() {
      return _.get(this.oho_member_preferences, "_id")
    },
  },
  methods: {
    ...mapMutations(["setAlertChangeRoleDialog"]),
    async handleConfirmAckedChangedRole() {
      const isReload = !!this.alert_changes_role.role
      try {
        await this.$ohoMemberApi.$patch(
          `${ep.member_preference}/${this.member_preferences_id}`,
          {
            acked_role_change: { should_ack: false, changed_by: null },
          }
        )
        this.setAlertChangeRoleDialog({ should_ack: false })
        if (isReload) {
          location.reload()
        }
      } catch (error) {
        this.$logger.error("Error update flag acked_role_change", error)
      } finally {
        this.show_dialog = false
      }
    },
  },
}
