const title = [
  {
    id: "tp-01-001",
    temp: `<div class="title">
      <div class="icon">
        <img src="{{ icon }}" class="image" />
      </div>
      <span>{{ channel_name }}</span>
    </div>`,
  },
  {
    id: "tp-01-002",
    temp: `<div class="title"></div>`,
  },
]

const message = [
  {
    id: "tp-02-001",
    temp: `<div class="content-wrapper">
      <div class="content">
        <div class="image">
          <img
            src="{{ contact_image_url }}"
            onerror="this.onerror=null; this.src='{{ user_placeholder_image }}';"
          />
        </div>
        <div>
          <p class="contact-name"> {{ this.$textHelper.displayTextContentWithLimit(contact_name, 20) }} </p>
          <p class="message"> $message </p>
        </div>
      </div>
      <div class="action-wrapper">
        <div class="btn-action">{{ button_text }}</div>
      </div>
    </div>`,
  },
  {
    id: "tp-02-002",
    temp: `<div class="content-wrapper">
      <div class="content">
        <div class="image">
          <img
            src="{{ assigner_image_url }}"
            onerror="this.onerror=null; this.src='{{ user_placeholder_image }}';"
          />
        </div>
        <div>
          <p class="contact-name"> {{ this.$textHelper.displayTextContentWithLimit(assigner_name, 20) }} </p>
          <p class="message"> $message </p>
        </div>
      </div>
      <div class="action-wrapper">
        <div class="btn-action">{{ button_text }}</div>
      </div>
    </div>`,
  },
  {
    id: "tp-02-004",
    temp: `<div class="content-wrapper">
      <div class="content">
        <p> $message </p>
      </div>
      <div class="action-wrapper">
        <div class="btn-action">{{ button_text }}</div>
      </div>
    </div>`,
  },
  {
    id: "tp-02-005",
    temp: `
      <div class="content-wrapper headless">
        <div class="content">
          <div style="background: {{ team_color_hex_code }}" class="image-team">
            <img
              src="{{ team_logo_url }}"
              onerror="this.onerror=null; this.src='{{ team_placeholder_image }}';"
            />
          </div>
          <div>
            <p class="contact-name"> {{ this.$textHelper.displayTextContentWithLimit(team_display_name, 20) }} </p>
            <p class="message"> $message </p>
          </div>
        </div>
      </div>
    `
  }
]

export default { title, message }
