import moment from "moment"

const savedReplyHelper = {
  isNewSavedReply(data) {
    const diff_created_at = moment().diff(data.created_at, "minutes")
    return diff_created_at <= 5
  },
  displayHighlightText(search, data) {
    return data.map((d) => {
      const name = _.get(d, "name", "")
      const highlight_text = $nuxt.$smartChatHelper.highlightAllSearchMatches(
        search,
        name
      )
      const messages = d.messages.map((msg) => {
        const text = _.get(msg, "text", "")
        const display_text = $nuxt.$smartChatHelper.highlightAllSearchMatches(
          search,
          text
        )

        return display_text
          ? {
              ...msg,
              display_text,
            }
          : msg
      })

      return highlight_text
        ? {
            ...d,
            highlight_text,
            messages,
          }
        : {
            ...d,
            messages,
          }
    })
  },
}
export default ({ app }, inject) => {
  inject("savedReplyHelper", savedReplyHelper)
}
