
export default {
  props: {
    html_msg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      overflow: false,
    }
  },
  mounted() {
    this.checkTooltip()
  },
  methods: {
    checkTooltip() {
      const offset = this.$refs.tooltip
      this.overflow = offset.scrollWidth > offset.offsetWidth
    },
  },
}
