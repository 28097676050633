export default () => {
  if (!process.client) return
  const env = process.env.app_env || "development"
  const gtm_id = process.env.gtm_id || "GTM-W2NWW5L"

  if (env == "production") {
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : ""
      j.async = true
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", gtm_id)
  }
  if (env == "uat") {
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : ""
      j.async = true
      j.src =
        "https://www.googletagmanager.com/gtm.js?id=" +
        i +
        dl +
        "&gtm_auth=AyRKuRnp3oEtlbSk7F8R_A&gtm_preview=env-13&gtm_cookies_win=x"
      f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", gtm_id)
  }
  if (env == "development" || env === "staging") {
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : ""
      j.async = true
      j.src =
        "https://www.googletagmanager.com/gtm.js?id=" +
        i +
        dl +
        "&gtm_auth=eOZ0o09uFoIBh98DFkONPg&gtm_preview=env-12&gtm_cookies_win=x"
      f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", gtm_id)
  }
}
