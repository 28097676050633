import _ from "lodash"

const noti = _.debounce(
  () => {
    const audio = new Audio("/sound-noti.wav")
    audio.play()
  },
  1000,
  { leading: true, trailing: false }
)

export default ({ app }, inject) => {
  if (!process.client) return
  inject("soundNoti", noti)
}
