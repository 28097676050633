import Vue from "vue"
import _ from "lodash"

const state = () => ({
  sort_chat_list: -1,
  filter_chat_list: [],
  last_filter_chat_list: [],
  last_contact_filter: [],
  last_case_filter: [],
  last_media_filter: [],
  last_saved_reply_filter: [],
})

const mutations = {
  setSortChatListFilter(state, data) {
    state.sort_chat_list = data
  },
  setFilterChatList(state, data) {
    state.filter_chat_list = data
  },
  setLastChatListFilter(state, data) {
    state.last_filter_chat_list = data
  },
  setLastContactFilter(state, data) {
    state.last_contact_filter = data
  },
  setLastCaseFilter(state, data) {
    state.last_case_filter = data
  },
  setLastMediaFilter(state, data) {
    state.last_media_filter = data
  },
  setLastSavedReplyFilter(state, data) {
    state.last_saved_reply_filter = data
  },
}

const actions = {
  addLastChatListFilter({ state, commit }, filter) {
    const data = handleLastFilter(filter, state.last_filter_chat_list)
    commit("setLastChatListFilter", data)
  },
  addLastContactFilter({ state, commit }, filter) {
    const data = handleLastFilter(filter, state.last_contact_filter)
    commit("setLastContactFilter", data)
  },
  addLastCaseFilter({ state, commit }, filter) {
    const data = handleLastFilter(filter, state.last_case_filter)
    commit("setLastCaseFilter", data)
  },
  addLastMediaFilter({ state, commit }, filter) {
    const data = handleLastFilter(filter, state.last_media_filter)
    commit("setLastMediaFilter", data)
  },
  addLastSavedReplyFilter({ state, commit }, filter) {
    const data = handleLastFilter(filter, state.last_saved_reply_filter)
    commit("setLastSavedReplyFilter", data)
  },
  removeSelectedTagFilter({ state }, tag) {
    const filter_index = _.findIndex(state.filter_chat_list, [
      "filter_type",
      _.get(tag, "filter_type.value"),
    ])
    const filter_val = _.get(
      state.filter_chat_list,
      `[${filter_index}].filter_val`,
      []
    )

    const item_index = _.findIndex(filter_val, ["value", tag.value])
    const filtered_data = filter_val.filter((_, i) => i !== item_index)

    Vue.set(state.filter_chat_list[filter_index], "filter_val", filtered_data)
    _.remove(state.filter_chat_list, (d) => d.filter_val == 0)
  },
}

const handleLastFilter = (filter, last_filter = []) => {
  const clone_filter = _.cloneDeep(filter)
  const clone_last_filter = _.cloneDeep(last_filter)
  const length = _.get(clone_last_filter, "length", 0)

  const filter_curr_equal_filter_last = _.isEqual(
    _.get(clone_last_filter, "[0]", []),
    clone_filter
  )

  if (filter_curr_equal_filter_last) return

  if (length < 10) {
    clone_last_filter.unshift(clone_filter)
  } else {
    clone_last_filter.splice(length - 1, 1)
    clone_last_filter.unshift(clone_filter)
  }

  return clone_last_filter
}

export default { state, mutations, actions }
