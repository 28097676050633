import numeral from "numeral"

const formatNumeral = {
  // 0,0
  customFormat: (val, format) => {
    return numeral(val).format(format)
  },
  // Return value type "String" and "Number"
  function: (val, format = "") => {
    const number = numeral(val)
    return {
      value: val,
      format,
      number_str: number.format(format),
      number_value: number.value(),
    }
  },
  // 1,333.333 => 1,333.3 (format: '.0')
  // 1,333.00 => 1,333 ({tofixed: false})
  // 1,333.00 => 1,333.00 ({tofixed: true})
  numbers: (val, format, { tofixed } = false) => {
    let numeral_format = "0,0"
    if ((format && val % 1 !== 0) || (format && tofixed)) {
      numeral_format += format
    }

    return numeral(val).format(numeral_format)
  },
  // 0.333 => 33%
  // 0.333 => 33.3% (format: '.0')
  percentages: (val, format) => {
    let numeral_format = format ? `0${format}%` : "0%"

    return numeral(val).format(numeral_format)
  },
  abbreviationNumber: (val, boundary = 0) => {
    const format = boundary && val >= boundary ? "0a" : "0,0"
    return numeral(val).format(format)
  },
}

export default ({ app }, inject) => {
  inject("format", formatNumeral)
}
