
import { mapState } from "vuex"

export default {
  props: {
    platform: {
      type: String,
      default: "facebook",
    },
    name: {
      type: String,
      default: "Username",
    },
    is_highlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      icon: (state) => state.icon,
    }),
  },
}
