
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#FFF6E8",
    },
    closable: {
      type: Boolean,
      default: false,
    },
    is_highlight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onRemoveTag() {
      this.$emit("removeTag", { id: this.id, tag: this.tag })
    },
  },
}
