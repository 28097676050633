
import { mapState, mapMutations } from "vuex"
import _ from "lodash"
import ep from "~/api/endpoint"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog_visible: false,
      menu_active: "noti",
      nav_menu_setting: [
        {
          label: "การแจ้งเตือน",
          value: "noti",
          icon: "notifications",
        },
        {
          label: "แชท",
          value: "chat_page",
          icon: "chat_bubble_outline",
        },
      ],
      noti_setting: {
        enable_noti: true,
      },
      chat_setting: {
        landing_page: "all_chat",
        display_time: "relative_time",
      },
      chat_landing_page_option: [
        {
          name: "แชททั้งหมด",
          value: "all_chat",
          data_tracking_id: "setting_first_chat_all-chat_select",
        },
        {
          name: "แชทของคุณ",
          value: "my_chat",
          data_tracking_id: "setting_first_chat_my-chat_select",
        },
      ],
      relative_time: require("~/assets/images/relative_time.svg"),
      specific_time: require("~/assets/images/specific_time.svg"),
    }
  },
  computed: {
    ...mapState({
      business: (state) => state.business || {},
      oho_member: (state) => state.oho_member,
    }),
    is_enabled_notification() {
      return _.get(this.oho_member, "member.is_enabled_notification.web_app")
    },
    chat_landing_page_update_permission() {
      return this.$permission.validate("member.chat-landing-page.update")
    },
  },
  watch: {
    visible: {
      handler(val) {
        this.dialog_visible = val
        this.setNotiValue()
        this.setChatValue()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setOhoMember"]),
    onClickMenu(menu) {
      this.menu_active = menu
    },
    onChangeNoti: _.debounce(async function () {
      const current_notification_setting =
        _.get(this.oho_member, "member.is_enabled_notification") || {}

      try {
        const res = await this.$ohoMemberApi.$patch(ep.member_me, {
          is_enabled_notification: {
            ...current_notification_setting,
            web_app: this.noti_setting.enable_noti,
          },
        })

        this.setOhoMember({
          ...this.oho_member,
          member: { ...this.oho_member.member, ...res },
        })
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    }, 300),
    validatePermission(permission) {
      const member_permissions = _.get(
        this.oho_member,
        "member.role_permission.permissions"
      )

      return _.includes(member_permissions, permission)
    },
    onChangeChatLandingPage: _.debounce(async function () {
      const current_chat_landing_page_setting =
        _.get(this.oho_member, "member.chat_landing_page") || {}

      try {
        const res = await this.$ohoMemberApi.$patch(ep.member_me, {
          chat_landing_page: {
            ...current_chat_landing_page_setting,
            web_app: this.chat_setting.landing_page,
          },
        })

        this.setOhoMember({
          ...this.oho_member,
          member: { ...this.oho_member.member, ...res },
        })
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    }, 300),
    onChangeDisplayChatRoomTime: _.debounce(async function () {
      const current_chat_list_display_time_setting =
        _.get(this.oho_member, "member.chat_list_display_time") || {}

      try {
        const res = await this.$ohoMemberApi.$patch(ep.member_me, {
          chat_list_display_time: {
            ...current_chat_list_display_time_setting,
            web_app: this.chat_setting.display_time,
          },
        })

        this.setOhoMember({
          ...this.oho_member,
          member: { ...this.oho_member.member, ...res },
        })
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    }, 300),
    setNotiValue() {
      this.noti_setting.enable_noti = this.is_enabled_notification
    },
    setChatValue() {
      this.chat_setting.landing_page = _.get(
        this.oho_member,
        "member.chat_landing_page.web_app"
      )
      this.chat_setting.display_time = _.get(
        this.oho_member,
        "member.chat_list_display_time.web_app"
      )
    },
  },
}
