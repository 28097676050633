
import { mapState } from "vuex"
import _ from "lodash"

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    image_url: {
      type: String,
      defualt: "",
    },
    size: {
      type: String,
      default: "md", // xs, sm, md, lg, xl
    },
    is_hide_online_status: {
      type: Boolean,
      defualt: false,
    },
  },
  data() {
    return {
      is_thumbnail: false,
      user_placeholder_image: require("~/assets/images/Property 1=Empty.png"),
      online_status: "offline", // "online", "away", "offline"
    }
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.findMemberOnlineStatus()
      },
    },
    members: {
      immediate: true,
      deep: true,
      handler() {
        this.findMemberOnlineStatus()
      },
    },
    current_contact: {
      handler() {
        this.findMemberOnlineStatus()
      },
    },
  },
  computed: {
    ...mapState({
      members: (state) => state.online_status.members || [],
      current_contact: (state) => state.smartchat.current_contact,
    }),
    display_image() {
      // Use thumbnail image of original image first
      const image = this.$thumbnailHelper.replaceImageUrl(
        this.image_url,
        "icon"
      )
      this.is_thumbnail = true
      return image
    },
    online_status_class() {
      switch (this.online_status) {
        case "online":
          return "is-online"
        case "away":
          return "is-away"
        case "offline":
          return "is-offline"
        default:
          return "is-offline"
      }
    },
  },
  methods: {
    replaceByDefault(e) {
      if (this.is_thumbnail) {
        // If cannot fetch thumbnail image, use original image
        e.target.src = this.image_url
        this.is_thumbnail = false
      } else {
        // If cannot fetch original image, show placeholder image
        e.target.src = this.user_placeholder_image
      }
    },
    findMemberOnlineStatus() {
      const member = _.find(this.members, {
        _id: this.id,
      })
      const online_status = _.get(member, "online_status") || "offline"
      this.online_status = online_status
    },
  },
}
