import _ from "lodash"
import ep from "~/api/endpoint"
import { Message } from "element-ui"
import moment from "moment"

const state = () => ({
  auto_reply_member: null,
  is_saving: false,
  is_saved: false,
  is_fail: false,
  last_updated_by: "",
  last_updated_at: "",
  arp_platforms: [
    { id: "greeting_message", label: "ข้อความต้อนรับ" },
    { id: "welcome_back_message", label: "ข้อความต้อนรับ (ติดต่อซ้ำ)" },
    { id: "main_menu", label: "เมนูหลัก" },
    { id: "fallback_message", label: "ข้อความเมื่อบอทตอบไม่ได้" },
  ],
  filter_values: [],
  filter_displays: [],
  sort: "updated_at",
  order_by: -1,
  arp_search: "",
  crp_sort: "updated_at",
  crp_order_by: -1,
  crp_search: "",
  crp_filter_values: [],
  crp_filter_displays: [],
  arp_form_active_tab: "message",
  current_arp: null,
  selected_platform: [],
  arp_messages: [],
  last_checked_channels: [],
})

const mutations = {
  setAutoReplyMember(state, member) {
    state.auto_reply_member = member
  },
  setSearchArp(state, search) {
    state.arp_search = search
  },
  setSearchCrp(state, search) {
    state.crp_search = search
  },
  setFilterArpValues(state, data = []) {
    state.filter_values = data
  },
  setFilterCrpValues(state, data = []) {
    state.crp_filter_values = data
  },
  setFilterSort(state, data) {
    state.sort = data
  },
  setFilterOrderBy(state, data) {
    state.order_by = data
  },
  setFilterCrpSort(state, data) {
    state.crp_sort = data
  },
  setFilterCrpOrderBy(state, data) {
    state.crp_order_by = data
  },
  setFilterArpDisplay(state, data = []) {
    state.filter_displays = data
  },
  setFilterCrpDisplay(state, data = []) {
    state.crp_filter_displays = data
  },
  setIsSaving(state, boolean) {
    state.is_saving = boolean
  },
  setIsSaved(state, boolean) {
    state.is_saved = boolean
  },
  setIsFail(state, boolean) {
    state.is_fail = boolean
  },
  setLastUpdatedBy(state, data) {
    state.last_updated_by = data
  },
  setLastUpdatedAt(state, data) {
    state.last_updated_at = data
  },
  setArpFormActiveTab(state, data) {
    state.arp_form_active_tab = data
  },
  setCurrentArp(state, data) {
    state.current_arp = data
  },
  setAutoReplySelectedPlatform(state, data) {
    state.selected_platform = data
  },
  setAutoReplyMessages(state, data) {
    state.arp_messages = data
  },
  setAutoReplyLastCheckedChannels(state, data) {
    state.last_checked_channels = data
  },
}

const actions = {
  async getAutoReplyMember({ commit }) {
    try {
      const res = await this.$ohoMemberApi.$get(ep.member, {
        params: {
          $limit: 500,
          is_deactivated: false,
        },
      })
      commit("setAutoReplyMember", res || {})
    } catch (error) {
      this.$logger.error("Get member error", _.get(error, "response.data"))
    }
  },
  handleInfoUpdate({ rootState, commit }) {
    commit(
      "setLastUpdatedBy",
      _.get(rootState, "oho_member.member.display_name")
    )
    commit("setLastUpdatedAt", moment())
  },
  async updateArp({ commit, dispatch }, body) {
    commit("setIsFail", false)
    const arp_id = $nuxt.$route.params.detail
    try {
      commit("setIsSaving", true)
      await this.$ohoMemberApi.$patch(
        `${ep.auto_reply_pattern}/${arp_id}`,
        body
      )
      dispatch("handleInfoUpdate")
      setTimeout(() => {
        commit("setIsSaving", false)
        commit("setIsSaved", true)
      }, 1000)
    } catch (error) {
      commit("setIsSaving", false)
      commit("setIsFail", true)
      Message.error(_.get(error, "response.data"))
    }
  },
  async updateArpExternalApi({ commit, dispatch }, body) {
    commit("setIsFail", false)
    const arp_id = $nuxt.$route.params.detail
    try {
      commit("setIsSaving", true)
      const res = await this.$ohoMemberApi.$patch(
        `${ep.auto_reply_pattern}/${arp_id}`,
        body
      )
      commit("setCurrentArp", res)
      dispatch("handleInfoUpdate")
      setTimeout(() => {
        commit("setIsSaving", false)
        commit("setIsSaved", true)
      }, 1000)
    } catch (error) {
      commit("setIsSaving", false)
      commit("setIsFail", true)
      Message.error(_.get(error, "response.data"))
    }
  },
}

const getters = {
  arp_platforms_id(state) {
    return _.map(state.arp_platforms, "id")
  },
  arp_has_some_custom_payload(state) {
    return _.some(state.arp_messages, ({ type }) => type === "custom")
  },
  is_arp_valid_custom_payload_channel(state, getters, rootState) {
    const biz_channels = rootState.channels
    const platforms_data = _.isEmpty(state.selected_platform)
      ? []
      : biz_channels
          .map((channel) => {
            const find = state.selected_platform.find(
              (platform) =>
                platform.is_enabled && platform.channel_id === channel._id
            )
            return find ? channel : ""
          })
          .filter((channel) => channel)

    return _.isEmpty(platforms_data)
      ? false
      : _.every(platforms_data, ({ platform }) => platform === "line")
  },
}

export default { state, mutations, actions, getters }
